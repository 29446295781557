<template>
  <div class="datepicker-box">
    <form-label
      v-if="label"
      class="datepicker-box__label"
      v-bind:icon="label_icon"
      v-bind:label="label"
      v-bind:required="required"
      v-bind:not_required_label="not_required_label" />
    <div class="flex w-full items-center space-x-2">
      <font-awesome-icon
        size="lg"
        v-if="left_icon"
        class="-mt-0.5 text-core-900"
        v-bind:icon="left_icon" />
      <vue-date-picker
        ref="datepicker"
        v-bind:id="id"
        v-model="local_date"
        calendar-class="datepicker-calendar"
        input-class="datepicker-input"
        v-bind:append-to-body="append_to_body"
        v-bind:open-date="local_date ? local_date : min_date"
        v-bind:placeholder="local_placeholder"
        v-bind:class="`datepicker ${class_name ? class_name : ''}`"
        v-bind:clear-button="show_clear_button"
        v-bind:typeable="!not_typeable"
        v-bind:disabled="disabled"
        v-bind:auto-apply="true"
        v-bind:enable-time-picker="false"
        v-bind:min-date="min_date"
        v-bind:max-date="max_date"
        locale="fr"
        v-bind:format="customFormatter"
        v-bind:parser="customParser"
        v-bind:monday-first="true"
        v-on:opened="toggleOpen(true)"
        v-on:closed="toggleOpen(false)"
        v-bind:always-clearable="true"
        v-bind:teleport="true"
        v-on:update:model-value="updateValue">
        <template v-slot:input-icon> </template>
        <template v-slot:clear-icon="{ clear }">
          <font-awesome-icon
            v-on:click="clear"
            icon="times-circle"
            class="mr-1 hover:text-jinius-red" />
        </template>
      </vue-date-picker>
    </div>
  </div>
</template>

<script>
  import formLabel from '@/components/utils/form-label.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import { format, parse } from 'date-fns';
  import frLocale from 'date-fns/locale/fr';

  export default {
    components: {
      formLabel,
      VueDatePicker
    },
    props: {
      id: {
        type: String,
        default: 'local_input'
      },
      disabled: { type: Boolean },
      append_to_body: {
        type: Boolean,
        default: null
      },
      not_typeable: {
        type: Boolean,
        default: false
      },
      not_required_label: {
        type: Boolean,
        default: false
      },
      show_clear_button: {
        type: Boolean,
        default: false
      },
      value: { required: true },
      label: { type: String },
      compare_type: {
        type: String,
        default: 'start_date'
      },
      label_icon: { type: [String, Array] },
      min_date: { type: Date },
      max_date: { type: Date },
      format: { type: String },
      class_name: { type: String },
      placeholder: { type: String },
      left_icon: { type: [String, Array] },
      required: {
        type: Boolean,
        default: false
      }
    },
    emits: ['input'],
    data() {
      return {
        opener: false,
        local_date: this.value
      };
    },
    computed: {
      local_placeholder() {
        return this.opener ? '' : this.placeholder;
      }
    },
    watch: {
      value(newVal, oldVal) {
        if (!newVal) {
          this.local_date = oldVal;
        } else {
          this.local_date = newVal;
        }
      }
    },
    methods: {
      toggleOpen(bool) {
        this.opener = bool;

        // Select input's value on focus after event "opened"
        const input = document.getElementById(this.id);
        const hasFocus = input === document.activeElement;

        this.opener && hasFocus && input.select();
      },
      updateValue(e) {
        this.local_date = e;
        this.$emit('input', this.local_date);
      },
      customParser(date) {
        return parse(
          date,
          this.format ? this.format : 'EEEE d MMMM y',
          new Date(),
          { locale: frLocale }
        );
      },
      customFormatter(date) {
        const formatedDate = format(
          date,
          this.format ? this.format : 'EEEE d MMMM y',
          { locale: frLocale }
        );

        return formatedDate.replace(/^\w/, (c) => c.toUpperCase());
      }
    }
  };
</script>

<style lang="scss" scoped>
  .datepicker-box {
    @apply flex w-full flex-wrap;

    .datepicker-box__label {
      @apply mb-2 font-bold leading-none;
    }

    :deep(.datepicker) {
      @apply w-full items-center text-core-700;

      &:not(.no-box) {
        @apply border border-core-700;
      }

      &.no-box {
        @apply flex-grow border-0 transition duration-1000 ease-in-out;

        .dp__input {
          @apply border-0 border-b-2 text-center outline-none;
        }

        &:hover {
          .dp__input {
            @apply bg-white;
          }
        }
      }

      .vdp-datepicker__clear-button {
        @apply absolute right-0 mr-3 mt-3;
      }

      .datepicker-input {
        @apply w-full cursor-pointer truncate rounded bg-transparent px-4 py-2.5;
      }

      .datepicker-calendar {
        @apply w-full rounded-b-lg bg-white shadow;
        min-width: 300px;

        .cell {
          &:not(.selected):not(.blank):not(.disabled).day:hover,
          &:not(.selected):not(.blank):not(.disabled).month:hover,
          &:not(.selected):not(.blank):not(.disabled).year:hover {
            @apply border border-core-300 bg-core-100;
          }

          &.selected {
            @apply border border-core-300 bg-core-200 font-bold;

            &:hover {
              @apply border border-core-500;
            }
          }
        }
      }

      &:focus {
        @apply border-core-500 outline-none;
      }
    }
  }

  .datepicker-error {
    @apply w-full border border-jinius-red text-jinius-red #{!important};
    .left__icon {
      @apply text-jinius-red;
    }

    label {
      @apply text-jinius-red;
    }
    input::placeholder {
      @apply text-jinius-red;
    }
  }
</style>
