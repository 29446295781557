<template>
  <refonte-filters-tabs-item
    label="Inscriptions"
    v-bind:selected="
      filters.statutIds &&
      filters.statutIds.join(', ') === INSCRIPTIONS_STATUTS_IDS.join(', ')
    "
    v-bind:on_click="
      () => {
        inscriptionFilterStore.setFilter(
          INSCRIPTIONS_FILTERS_CONFIGURATION.statutIds.name,
          INSCRIPTIONS_STATUTS_IDS
        );
      }
    " />
  <refonte-filters-tabs-item
    label="Inscriptions annulées/transférées"
    v-bind:selected="
      filters.statutIds &&
      filters.statutIds.join(', ') ===
        INSCRIPTIONS_ANNULEES_STATUTS_IDS.join(', ')
    "
    v-bind:on_click="
      () => {
        inscriptionFilterStore.setFilter(
          INSCRIPTIONS_FILTERS_CONFIGURATION.statutIds.name,
          INSCRIPTIONS_ANNULEES_STATUTS_IDS
        );
      }
    " />
</template>

<script setup lang="ts">
  import {
    INSCRIPTIONS_ANNULEES_STATUTS_IDS,
    INSCRIPTIONS_FILTERS_CONFIGURATION,
    INSCRIPTIONS_STATUTS_IDS,
    useInscriptionFilterStore
  } from '@/stores/inscriptions';
  import { storeToRefs } from 'pinia';
  import RefonteFiltersTabsItem from '@/components/refonte/filters/refonte-filters-tabs-item.vue';

  const inscriptionFilterStore = useInscriptionFilterStore();

  const { filters } = storeToRefs(inscriptionFilterStore);
</script>

<style lang="scss" scoped></style>
