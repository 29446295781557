<template>
  <div class="table-item-row">
    <IconPastilleActive
      v-if="props.row.compteJinius"
      v-tooltip="{
        content: 'Compte Jinius activé'
      }" />
    <IconPastilleCanceled
      v-else
      v-tooltip="{
        content: 'Compte Jinius non activé'
      }" />
    <div>
      <span>{{ props.row.nomPrenom }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconPastilleActive from '@/assets/img/refonte/icons/states/icon-pastille-active.svg?component';
  import IconPastilleCanceled from '@/assets/img/refonte/icons/states/icon-pastille-canceled.svg?component';
  import { Participant } from '@/types/gestion/inscriptions-details-types';

  const props = withDefaults(
    defineProps<{
      row: Participant;
    }>(),
    {}
  );
</script>

<style lang="scss" scoped>
  .table-item-row {
    @apply flex items-center;
    gap: 8px;
  }
</style>
