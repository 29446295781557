<template>
  <template v-if="!loading && detail">
    <sessions-details-main
      v-bind:detail="detail"
      v-bind:client_id="clientId"
      v-bind:no_cursus_detail="true" />
    <refonte-collapse
      v-if="detail.titre && detail.participants.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      v-bind:title="`Participants (${detail.participants.length})`"
      v-bind:start_open="true">
      <template v-if="detail.participants">
        <refonte-table
          v-bind:on_details="goToDetailsInscription"
          key="participants-1"
          table_index="participants-1"
          v-bind:columns="
            detail.lienElearning
              ? columnsParticipantAvecElearning
              : columnsParticipant
          "
          v-bind:data="detail.participants">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="
        detail.conventions?.length ||
        detail.pifs?.length ||
        !detail.clientIsOrganismeFormation
      "
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Documents"
      v-bind:start_open="true">
      <div class="flex flex-wrap gap-[8px] pb-[12px]">
        <template v-if="detail.conventions?.length">
          <refonte-item-file
            v-if="
              detail.conventions?.length === 1 &&
              detail.conventions[0].files.length === 1
            "
            v-bind:subtitle="detail.conventions[0].files[0].subtitle"
            id="convention"
            title="Convention de formation"
            v-bind:on_click_download="
              () =>
                downloadConvention(
                  detail.conventions[0].files[0]?.clientId,
                  detail.conventions[0].files[0]
                )
            "
            v-bind:file_signed="detail.conventions[0].files[0].signed"
            v-bind:upload_url="
              getConventionUploadUrl(
                detail.conventions[0].files[0]?.clientId,
                detail.conventions[0].files[0]
              )
            "
            v-bind:on_click_upload_refresh="fetch_formation" />
          <refonte-item-card-with-button
            v-else
            title="Conventions"
            v-bind:on_click="() => setIsConventionModalOpen(true)" />
        </template>
        <template v-if="detail.pifs?.length">
          <refonte-item-file
            v-if="detail.pifs[0].files.length === 1"
            title="PIF"
            id="pif"
            v-bind:on_click_download="
              () => downloadPif(props.row.clientId, pif)
            "
            signed_label="Signé"
            v-bind:subtitle="detail.pifs[0].files[0].nomPrenomParticipant ?? ''"
            v-bind:file_signed="detail.pifs[0].files[0].signed"
            v-bind:upload_url="getPifUploadUrl(pif?.clientId, pif)"
            v-bind:on_click_upload_refresh="fetch_formation" />
          <refonte-item-card-with-button
            v-else
            title="PIFs"
            v-bind:on_click="() => setIsPIFModalOpen(true)" />
        </template>
        <refonte-item-file
          id="certificat"
          v-if="!detail.clientIsOrganismeFormation"
          title="Certificat de réalisation (.zip)"
          v-bind:subtitle="detail.hasCertificats ? 'Tous les participants' : ''"
          v-bind:no_download_text="detail.hasCertificats ? '-' : 'À venir'"
          v-bind:on_click_download="
            detail.hasCertificats
              ? () => downloadCertificatZip(detail?.id)
              : undefined
          "
          v-bind:no_signature_needed="true" />
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.prestations && detail.prestations.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconList"
      title="Prestations"
      v-bind:start_open="true">
      <template v-if="detail.prestations">
        <refonte-table
          key="prestations-1"
          table_index="prestations-1"
          v-bind:columns="columnsPrestationsPrices"
          v-bind:data="detail.prestations">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.formations && detail.formations.length"
      class="mt-[12px]"
      v-bind:title_icon="IconFormations"
      title="Modules"
      v-bind:start_open="true">
      <template v-if="detail.formations.length">
        <refonte-table
          v-bind:on_details="goToDetailsFormation"
          key="formations-cursus-1"
          table_index="formations-cursus-1"
          v-bind:columns="columnsFormations"
          v-bind:data="detail.formations.map((f) => formatFormation(f))">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.programmes && detail.programmes.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconDayAgenda"
      title="Détails de la programmation"
      v-bind:start_open="true">
      <template v-if="detail.programmes.length">
        <div
          v-for="(programme, index) in detail.programmes"
          v-bind:key="`programme-${index}`">
          <refonte-separator
            v-if="index > 0 && detail.programmes.length > 1"
            class="px-[12px]" />
          <refonte-label
            v-if="programme.titre && detail.programmes.length > 1"
            v-bind:label="programme.titre"
            class="px-[16px]" />
          <refonte-table
            key="prestations-1"
            table_index="prestations-1"
            v-bind:columns="columnsPrestations"
            v-bind:data="
              programme.prestations.map((p: Prestation) => ({
                ...p,
                nom: p.nom ? p.nom : p.typeId == 2 ? 'Repas' : p.titre,
                lieuNom: p.site ? p.site.denomination : undefined
              }))
            ">
          </refonte-table>
        </div>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.factures && detail.factures.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <template v-if="detail.factures && detail.factures.length > 0">
        <refonte-table
          key="factures-1"
          table_index="factures-1"
          v-bind:columns="columnsFactures"
          v-bind:data="detail.factures">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
  <refonte-modal-file-section-list
    v-if="detail"
    v-bind:show="isConventionModalOpen"
    v-bind:on_close="() => setIsConventionModalOpen(false)"
    title="Convention(s)"
    v-bind:sections="detail.conventions"
    v-bind:build_upload_url_for_file="
      (convention) => getConventionUploadUrl(convention.clientId, convention)
    "
    v-bind:file_on_click_upload_url="
      () => {
        fetch_formation();
      }
    "
    v-bind:file_on_click_download="
      (convention) => downloadConvention(convention.clientId, convention)
    " />
  <refonte-modal-file-section-list
    v-if="detail"
    v-bind:show="isPIFModalOpen"
    v-bind:on_close="() => setIsPIFModalOpen(false)"
    title="PIF(s)"
    v-bind:sections="detail.pifs"
    v-bind:file_on_click_download="
      (pif) => downloadPif(props.row.clientId, pif)
    "
    v-bind:build_upload_url_for_file="(pif) => getPifUploadUrl(pif)"
    v-bind:file_on_click_upload_url="
      () => {
        fetch_formation();
      }
    "
    file_signed_label="Signé" />
  <refonte-modal-satisfaction-participant
    v-if="selectedParticipant"
    v-bind:show="isParticipantSatisfactionModalOpen"
    v-bind:on_close="closeParticipantSatisfactionModal"
    v-bind:participant="selectedParticipant"
    v-bind:session_reference="detail.sessionReference"
    v-bind:client_id="selectedParticipant.clientId" />
</template>

<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, h, ref } from 'vue';
  import SessionsDetailsMain from '@/views/_espace/_refonte/gestion/_sessions-details/sessions-details-main.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconDayAgenda from '@/assets/img/refonte/icons/information/icon-day-agenda.svg?component';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import IconEye from '@/assets/img/refonte/icons/display/icon-eye.svg?component';
  import {
    Participant,
    Prestation,
    PrestationPrice
  } from '@/types/gestion/inscriptions-details-types';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import IconList from '@/assets/img/refonte/icons/information/icon-list.svg?component';
  import number_utils from '@/mixin/number_utils';
  import { Facture } from '@/types/gestion/factures-types';
  import { useRoute, useRouter } from 'vue-router';
  import { SessionFormation } from '@/types/gestion/sessions-types';
  import {
    downloadCertificat,
    downloadCertificatZip,
    downloadConvention,
    downloadPif,
    getConventionUploadUrl,
    getPifUploadUrl
  } from '@/mixin/client_download__functions';
  import { Inscription } from '@/types/gestion/inscriptions-types';
  import {
    buildProduitTypeLabel,
    buildProduitTypeShortcut
  } from '@/stores/utils';
  import RefonteModalFileSectionList from '@/components/refonte/modals/refonte-modal-file-section-list.vue';
  import RefonteItemCardWithButton from '@/components/refonte/items/refonte-item-card-with-button.vue';
  import RefonteModalSatisfactionParticipant from '@/components/refonte/modals/refonte-modal-satisfaction-participant.vue';
  import { Detail } from '@/types/gestion/sessions-details-types';
  import RefonteTableItemParticipantNameAccountStatus from '@/components/refonte/tables/refonte-table-item-participant-name-account-status.vue';

  const loading = ref<boolean>(false);

  const route = useRoute();
  const clientId = computed(() => route.query.clientId);

  const props = withDefaults(
    defineProps<{
      fetch_formation?: Function;
      detail: Detail;
    }>(),
    {
      fetch_formation: () => {}
    }
  );

  const columnsPrestations = [
    {
      content_index: 'nom',
      date: 'date',
      width: 308
    },
    {
      content_index: 'heureDebut',
      label: 'Heure de début',
      width: 120,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(row.heureDebut)
        })
    },
    {
      content_index: 'heureFin',
      label: 'Heure de fin',
      width: 120,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(row.heureFin)
        })
    },
    {
      content_index: 'dureeMinutes',
      label: 'Durée',
      width: 90,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: row.dureeMinutes
            ? dates_helpers.methods.formatMinutesToHours(row.dureeMinutes)
            : '-'
        })
    },
    {
      content_index: 'lieuNom',
      label: 'Site',
      width: 150
    }
  ];

  const columnsPrestationsPrices = [
    {
      content_index: 'libelle',
      label: 'Libellé',
      width: 308
    },
    {
      content_index: 'montantHT',
      label: 'Montant HT',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantHT)
        })
    },
    {
      content_index: 'montantTVA',
      label: 'Montant TVA',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTVA)
        })
    },
    {
      content_index: 'montantTTC',
      label: 'Montant TTC',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTTC)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Montant réglé',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    }
  ];

  const columnsParticipant = [
    {
      content_index: 'nomPrenom',
      label: 'Nom',
      width: 308,
      content_custom: (row: Participant) =>
        h(RefonteTableItemParticipantNameAccountStatus, { row })
    },
    {
      content_index: 'statut',
      label: 'Inscription',
      width: 120
    },
    {
      content_index: 'datesAcces',
      label: "Dates d'accès",
      width: 120,
      class: (row: Participant) => (row.elearningLabel ? '' : '!hidden')
    },
    {
      content_index: 'positionnement',
      label: 'T. Positionnement',
      width: 140,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.positionnement
            ? row.positionnement.label
            : 'Non requis'
        }),
      class: (row: Participant) => (row.elearningLabel ? '!hidden' : '')
    },
    ...(!props.detail.clientIsOrganismeFormation
      ? [
          {
            content_index: 'validation',
            label: 'T. valid. acquis',
            width: 120,
            content_custom: (row: Participant) =>
              h('span', {
                innerHTML: row.validation ? row.validation.label : 'Non requis'
              })
          }
        ]
      : []),

    {
      content_index: 'satisfaction',
      label: 'Satisfaction',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        }),
      content_icon: (row: Participant) =>
        row.satisfaction?.label === 'Réalisé' ? IconEye : undefined,
      on_click_disabled: (row: Participant) =>
        row.satisfaction && row.satisfaction.label !== 'Réalisé',
      class: () => '!flex-grow-0',
      on_click: (row: Participant) => {
        selectedParticipant.value = row;
        isParticipantSatisfactionModalOpen.value = true;
      }
    },
    ...(!props.detail.clientIsOrganismeFormation
      ? [
          {
            content_index: 'certificatId',
            label: 'Certificat',
            min_width: 117,
            width: 130,
            content_custom: (row: Participant) =>
              row.certificatId
                ? h('span', {
                    innerHTML: 'Télécharger'
                  })
                : '',
            on_click: (row: Participant) => {
              if (row.certificatId && row.clientId) {
                downloadCertificat(row.clientId, row.certificatId);
              }
            },
            content_icon: (row: Participant) =>
              row.certificatId ? IconDownload : undefined,
            class: (row: Participant) => '!flex-grow-0'
          }
        ]
      : [])
  ];

  const columnsParticipantAvecElearning = [
    ...columnsParticipant,
    {
      content_index: 'elearningLabel',
      label: 'Elearning',
      width: 120
    }
  ];

  const columnsFactures = [
    {
      content_index: 'reference',
      label: 'Référence',
      width: 308
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 180
    },
    {
      content_index: 'date',
      label: 'Date',
      width: 120
    },
    {
      content_index: 'montant',
      label: 'Montant',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montant)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Payé',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    },
    {
      content_index: 'factureNom',
      label: 'Facture',
      width: 140,
      on_click: (rowIndex: number) => {},
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];

  const columnsFormations = [
    {
      content_index: 'titre',
      tags_type: 'typeShortcut',
      tags_type_label: 'typeLabel',
      content_sub_text: (row: Inscription) =>
        `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu ?? ''}`,
      width: 308
    },
    {
      content_index: 'datesFormations',
      label: 'Dates de formation',
      width: 190
    },
    {
      content_index: 'lieu',
      label: 'Site',
      width: 150
    }
  ];

  const formatFormation = (f) => {
    let dates = '-';
    if (f.dateDebut && f.dateFin) {
      if (f.dateDebut == f.dateFin) {
        dates = `${f.dateDebut}`;
      } else {
        dates = `${f.dateDebut} - ${f.dateFin}`;
      }
    }
    return {
      ...f,
      datesFormations: dates,
      typeShortcut: buildProduitTypeShortcut(f.type.id, f.lienElearning),
      typeLabel: buildProduitTypeLabel(f.type.label, f.lienElearning)
    };
  };
  const router = useRouter();
  const goToDetailsFormation = (row: SessionFormation) => {
    if (row.sessionId && props.detail?.type?.id === 4) {
      router.push(
        `/gestion/sessions-details?id=${row.sessionId}&produitId=${row.produitId}&clientId=${clientId.value}`
      );
    }

    if (props.detail?.type?.id === 8) {
      if (row.sessionId) {
        router.push(
          `/gestion/sessions-cursus-session-details?id=${row.sessionId}&clientId=${clientId.value}&cursusId=${props.detail.id}`
        );
      }
      if (row.ficheId) {
        router.push(
          `/gestion/sessions-cursus-elearning-details?id=${row.ficheId}&clientId=${clientId.value}&cursusId=${props.detail.id}`
        );
      }
    }
  };

  const goToDetailsInscription = (row: Participant) => {
    if (props.detail?.type?.id === 8) {
      router.push(`/gestion/inscriptions-cursus?id=${row.id}`);
    } else {
      router.push(`/gestion/inscriptions-session?id=${row.id}`);
    }
  };

  const isConventionModalOpen = ref<boolean>(false);
  const isPIFModalOpen = ref<boolean>(false);
  const isParticipantSatisfactionModalOpen = ref<boolean>(false);
  const selectedParticipant = ref<Participant>();

  function setIsConventionModalOpen(value: boolean) {
    isConventionModalOpen.value = value;
  }
  function setIsPIFModalOpen(value: boolean) {
    isPIFModalOpen.value = value;
  }
  function closeParticipantSatisfactionModal() {
    selectedParticipant.value = undefined;
    isParticipantSatisfactionModalOpen.value = false;
  }
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
