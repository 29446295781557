import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
import { typeMapping } from '@/types/gestion/inscriptions-types';

export const buildProduitTypeShortcut = (
    produitTypeId: number,
    lienElearning?: boolean
): string[] => {
    const typeShortcut = [typeMapping[produitTypeId]];
    if (lienElearning) {
        typeShortcut.push('e-learning');
    }
    return typeShortcut;
};

export const buildProduitTypeLabel = (
    produitTypeLabel: string,
    lienElearning?: boolean
): string[] => {
    const typeLabel = [produitTypeLabel];
    if (lienElearning) {
        typeLabel.push('e-learning');
    }
    return typeLabel;
};

export const addSessionTypeShortcut = (
    flags: string[],
    sessionStatutId: number
): string[] => {
    return sessionStatutId === 5 ? [...flags, 'annulee'] : flags;
};

export const addSessionTypeLabel = (
    flags: string[],
    sessionStatutId: number
): string[] => {
    return sessionStatutId === 5 ? [...flags, 'Annulée'] : flags;
};

export const buildDocumentStatut = (
    disponible?: boolean,
    signee?: boolean,
    validee?: boolean
) => {
    if (disponible && !signee && !validee) {
        return 'A signer';
    } else if (signee) {
        return 'Signée';
    } else if (validee) {
        return 'Validée';
    }
};

export const buildPifDocumentStatut = (
    disponible?: boolean,
    signee?: boolean,
    validee?: boolean
) => {
    if (disponible && !signee && !validee) {
        return 'A signer';
    } else if (signee) {
        return 'Signé';
    } else if (validee) {
        return 'Validé';
    }
};

export const buildDate = (dateDebut: string, dateFin: string): string => {
    if (dateDebut === dateFin) {
        return dateDebut;
    } else {
        return `${dateDebut} - ${dateFin}`;
    }
};

export const buildConventionSectionList = (
    conventions,
    produitTitre: string
) => {
    const conventionsCollectives = [];
    const conventionsIndividuelles = [];
    conventions.forEach((c) => {
        const file = {
            ...c,
            formation: c.produitTitre ? c.produitTitre : produitTitre,
            signed: c.signee || c.validee
        };
        if (c.typeId == 1) {
            conventionsCollectives.push({
                ...file,
                title: 'Convention de formation',
                subtitle: c.commandeReference
            });
        } else {
            conventionsIndividuelles.push({
                ...file,
                title: 'Convention individuelle',
                subtitle: c.nomPrenomParticipant
            });
        }
    });
    const sectionList = [];
    if (conventionsCollectives.length) {
        sectionList.push({
            title: 'Conventions de formation',
            icon: IconConventionCollective,
            files: conventionsCollectives
        });
    }
    if (conventionsIndividuelles.length) {
        sectionList.push({
            title: 'Conventions individuelles',
            icon: IconMyAccount,
            files: conventionsIndividuelles.sort((c1, c2) =>
                c1.subtitle.localeCompare(c2.subtitle)
            )
        });
    }
    return sectionList;
};

export const buildPifSectionList = (pifs, produitTitre: string) => {
    const files = pifs.map((p) => ({
        ...p,
        title: 'PIF',
        formation: p.produitTitre ? p.produitTitre : produitTitre,
        signed: p.signee || p.validee
    }));
    return files.length
        ? [
              {
                  files
              }
          ]
        : [];
};
