import { IdLabel } from '@/types/gestion/entreprise';
import { FormationResponse } from '@/types/participant/formations-types';

const types = {
    PRESENTIEL: {
        id: 1,
        label: 'Présentiel'
    },
    ELEARNING: {
        id: 2,
        label: 'E-learning'
    },
    WEBINAIRE: {
        id: 3,
        label: 'Webinaire'
    },
    PARCOURS: {
        id: 4,
        label: 'Parcours'
    },
    CLASSE_VIRTUELLE: {
        id: 5,
        label: 'Classe virtuelle'
    },
    CONFERENCE: {
        id: 6,
        label: 'Conférence'
    },
    ATELIER: {
        id: 7,
        label: 'Atelier'
    },
    CURSUS: {
        id: 8,
        label: 'Cursus'
    }
};

const statuts = {
    EN_ATTENTE: {
        id: 1,
        label: 'En attente'
    },
    CONFIRME: {
        id: 2,
        label: 'Confirmé'
    },
    ANNULE: {
        id: 3,
        label: 'Annulé'
    },
    TRANSFERE: {
        id: 4,
        label: 'Transféré'
    },
    SESSION_ANNULE: {
        id: 5,
        label: 'Annulé (Session annulée)'
    }
};

export const formationsData: FormationResponse[] = [
    {
        id: '1000000',
        reference: '02250154',
        dateDebut: '13/03/2025',
        dateFin: '13/03/2025',
        titre: "A attendre l'herbe qui pousse, le boeuf meurt",
        lieu: 'Paris',
        familleProduit: 'Préparation aux examens',
        organisateur: 'CNCC Formation',
        statut: statuts.EN_ATTENTE,
        type: types.PRESENTIEL,
        sessionStatut: statuts.CONFIRME,
        inProgress: true,
        attestationId: 45,
        testPositionnement: {
            disponible: true,
            realise: false
        }
    },
    {
        id: '1',
        reference: '02250155',
        dateDebut: '13/03/2025',
        dateFin: '13/03/2025',
        titre: "A attendre l'herbe qui pousse, le boeuf meurt",
        lieu: 'Paris',
        familleProduit: 'Préparation aux examens',
        organisateur: 'CNCC Formation',
        statut: statuts.EN_ATTENTE,
        lienElearning: true,
        type: types.PRESENTIEL,
        sessionStatut: statuts.CONFIRME,
        inProgress: true,
        attestationId: 45,
        testPositionnement: {
            disponible: true,
            realise: false
        },
        moduleElearning: {
            disponible: true,
            realise: false
        }
    },
    {
        id: '2',
        reference: '02.02250154',
        dateDebut: '10/02/2025',
        dateFin: '10/04/2025',
        titre: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
        lieu: 'En ligne',
        familleProduit: 'Préparation aux examens',
        organisateur: 'Auditec',
        statut: statuts.CONFIRME,
        type: types.ELEARNING,
        sessionStatut: statuts.CONFIRME,
        inProgress: true,
        pif: {
            id: 1,
            disponible: true,
            signee: false,
            validee: true
        },
        testValidationAcquis: {
            disponible: true,
            realise: false
        }
    },
    {
        id: '1001',
        reference: '02250155',
        dateDebut: '31/03/2025',
        dateFin: '02/04/2025',
        titre: "A attendre l'herbe qui pousse, le boeuf meurt",
        lieu: 'En ligne',
        familleProduit: 'Form. professionnelle',
        organisateur: 'Ifaura',
        statut: statuts.CONFIRME,
        type: types.CURSUS,
        sessionStatut: statuts.CONFIRME,
        pif: {
            id: 1,
            disponible: true,
            signee: false,
            validee: false
        },
        attestationId: 45,
        satisfaction: {
            disponible: true,
            realise: false
        }
    },
    {
        id: '4',
        reference: '02250156',
        dateDebut: '31/03/2025',
        dateFin: '31/03/2025',
        titre: 'Sous-session du cursus numéro 1',
        lieu: 'Paris',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CFPC',
        produit: {
            id: 1,
            titre: "A attendre l'herbe qui pousse, le boeuf meurt",
            type: types.CURSUS
        },
        statut: statuts.CONFIRME,
        type: types.PRESENTIEL,
        sessionStatut: statuts.CONFIRME,
        pif: {
            id: 1,
            disponible: true,
            signee: true,
            validee: false
        }
    },
    {
        id: '565',
        reference: '02250157',
        dateDebut: '02/04/2025',
        dateFin: '02/04/2025',
        titre: 'Sous-session du cursus numéro 2',
        lieu: 'Paris',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CNCC Formation',
        produit: {
            id: 1,
            titre: "A attendre l'herbe qui pousse, le boeuf meurt",
            type: types.CURSUS
        },
        statut: statuts.CONFIRME,
        type: types.PRESENTIEL,
        sessionStatut: statuts.CONFIRME,
        satisfaction: {
            disponible: true,
            realise: false
        },
        testValidationAcquis: {
            disponible: true,
            realise: false
        }
    },
    {
        id: '5',
        reference: '02250158',
        dateDebut: '07/04/2025',
        dateFin: '07/04/2025',
        titre: "Atelier CRCC : L'IA, un assistant virtuel à intégrer dans nos équipes d'audit ?",
        lieu: 'En ligne',
        familleProduit: 'Stage EC',
        organisateur: 'CNCC Formation',
        statut: statuts.CONFIRME,
        type: types.CLASSE_VIRTUELLE,
        sessionStatut: statuts.CONFIRME
    },
    {
        id: '3',
        reference: '02250160',
        dateDebut: '10/04/2025',
        dateFin: '10/04/2025',
        titre: "Panorama de l'actualité comptable 2025 (Stage EC)",
        lieu: 'En ligne',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CNCC Formation',
        statut: statuts.CONFIRME,
        type: types.WEBINAIRE,
        sessionStatut: statuts.CONFIRME
    },
    {
        id: '8',
        reference: '02250161',
        dateDebut: '15/04/2025',
        dateFin: '25/04/2025',
        titre: 'Parcours 1',
        lieu: 'Reims',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CNCC Formation',
        statut: statuts.CONFIRME,
        type: types.PARCOURS,
        sessionStatut: statuts.CONFIRME
    },
    {
        id: '9',
        reference: '02250170',
        dateDebut: '15/04/2025',
        dateFin: '15/04/2025',
        titre: 'Sous produit du parcours 1',
        lieu: 'Reims',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CNCC Formation',
        statut: statuts.CONFIRME,
        type: types.PRESENTIEL,
        produit: {
            id: 8,
            titre: 'Parcours 1',
            type: types.PARCOURS
        },
        sessionStatut: statuts.CONFIRME
    },
    {
        id: '10',
        reference: '02250171',
        dateDebut: '18/04/2025',
        dateFin: '19/04/2025',
        titre: 'Sous produit du parcours 2',
        lieu: 'Reims',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CNCC Formation',
        statut: statuts.CONFIRME,
        type: types.PRESENTIEL,
        produit: {
            id: 8,
            titre: 'Parcours 1',
            type: types.PARCOURS
        },
        sessionStatut: statuts.CONFIRME
    },
    {
        id: '10',
        reference: '02250172',
        dateDebut: '25/04/2025',
        dateFin: '25/04/2025',
        titre: 'Sous produit du parcours 3',
        lieu: 'En ligne',
        familleProduit: 'Form. professionnelle',
        organisateur: 'CNCC Formation',
        statut: statuts.CONFIRME,
        type: types.CONFERENCE,
        produit: {
            id: 8,
            titre: 'Parcours 1',
            type: types.PARCOURS
        },
        sessionStatut: statuts.CONFIRME
    }
];
