<template>
  <div>
    <refonte-modal
      v-bind:show="props.show"
      class="!max-w-[1200px]"
      v-on:close="openModalAlertClose">
      <template v-slot:modalHeader>
        <h1>Questionnaire de satisfaction</h1>
      </template>

      <template v-slot:modalBody>
        <div class="mb-8">
          <template v-if="loading">
            <spinner
              sizes="w-20 h-20"
              thickness="border-8" />
          </template>
          <template v-else>
            <template v-if="detail">
              <surveyjs-wrapper
                ref="surveyWrapper"
                v-on:complete="submit"
                v-bind:json="detail" />
              <div
                class="mx-10 font-bold italic text-orange-500"
                v-if="surveyCatcher && surveyCatcher.isLastPage">
                Pour votre information, en soumettant ce questionnaire, vous
                acceptez que vos réponses soient exploitées par votre employeur
                pour améliorer les pratiques internes, en conformité avec le
                RGPD.
              </div>
            </template>
          </template>
        </div>
      </template>

      <template v-slot:modalFooter>
        <div
          v-if="surveyCatcher"
          class="inline-flex w-full justify-end space-x-4 text-lg">
          <refonte-button
            v-if="!surveyCatcher.isFirstPage"
            v-bind:on_click="() => surveyWrapper.prevPage()">
            Précédent
          </refonte-button>
          <refonte-button
            v-if="!surveyCatcher.isLastPage"
            v-bind:on_click="() => surveyWrapper.nextPage()">
            Suivant
          </refonte-button>
          <refonte-button
            v-else
            v-bind:on_click="() => surveyWrapper.completeLastPage()"
            variant="success">
            Valider
          </refonte-button>
        </div>
      </template>
    </refonte-modal>
    <satisfaction-alert-modal-close
      v-bind:show="isModalAlertCloseOpen"
      v-bind:on_close="closeModalAlertClose"
      v-bind:on_submit="close" />
  </div>
</template>

<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import spinner from '@/components/utils/spinner';
  import { onMounted, ref, watchEffect } from 'vue';
  import { API } from '@/http-common';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import SatisfactionAlertModalClose from '@/views/_espace/_refonte/gestion/_satisfaction/satisfaction-alert-modal-close.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_success: Function;
      satisfaction: Object;
    }>(),
    {}
  );

  const detail = ref(null);
  const surveyCatcher = ref(null);
  const surveyWrapper = ref(null);
  const loading = ref<boolean>(false);
  const isModalAlertCloseOpen = ref<boolean>(false);

  onMounted(() => {
    fetchDetail();
  });

  watchEffect(() => {
    if (surveyWrapper.value) {
      surveyCatcher.value = surveyWrapper.value.survey;
    } else {
      // not mounted yet, or the element was unmounted (e.g. by v-if)
    }
  });
  const fetchDetail = async () => {
    loading.value = true;
    if (props.satisfaction.client.id && props.satisfaction.id) {
      const response = await API.get(
        `client/${props.satisfaction.client.id}/satisfaction/${props.satisfaction.id}`
      );
      if (response && response.data) {
        detail.value = response.data;
      }
    }
    loading.value = false;
  };
  const submit = async (result) => {
    loading.value = true;
    if (props.satisfaction.client.id && props.satisfaction.id) {
      const response = await API.post(
        `client/${props.satisfaction.client.id}/satisfaction/${props.satisfaction.id}`,
        result.data
      );
      if (response.status == 200) {
        close();
        props.on_success();
      }
    }
    loading.value = false;
  };
  const close = () => {
    detail.value = null;
    props.on_close();
  };
  const openModalAlertClose = () => {
    isModalAlertCloseOpen.value = true;
  };
  const closeModalAlertClose = () => {
    isModalAlertCloseOpen.value = false;
  };
</script>

<style lang="scss" scoped></style>
