<template>
  <template v-if="!loading && metadataLoaded">
    <refonte-card>
      <refonte-filters-tabs>
        <template v-slot:default>
          <formations-buttons-filters />
        </template>
      </refonte-filters-tabs>
      <formations-filters
        v-bind:metadatas="metadatas"
        v-if="metadataLoaded" />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <formations-content
        v-if="!dataLoading"
        v-bind:fetch="fetchData" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import { storeToRefs } from 'pinia';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import filters_utils from '@/mixin/utils/filters_utils';
  import {
    Filters,
    FormationMetadata
  } from '@/types/participant/formations-filters-types';
  import FormationsButtonsFilters from '@/views/_espace/_refonte/participant/_formations/formations-buttons-filters.vue';
  import FormationsFilters from '@/views/_espace/_refonte/participant/_formations/formations-filters.vue';
  import FormationsContent from '@/views/_espace/_refonte/participant/_formations/formations-content.vue';
  import {
    FORMATIONS_FILTERS_CONFIGURATION,
    useFormationFilterStore,
    useFormationStore
  } from '@/stores/participant/formations';

  const loading = ref(false);

  const formationFilterStore = useFormationFilterStore();

  const { filters } = storeToRefs(formationFilterStore);

  const dataLoading = ref<boolean>(false);
  const formationStore = useFormationStore();

  const fetchData = async () => {
    dataLoading.value = true;
    await formationStore.fetchFormations(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );
  const initFilters = async () => {
    formationFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(async () => {
    loading.value = true;
    await initFilters();
    await fetchDefaultData();
    await fetchMetadata();
    loading.value = false;
  });

  const router = useRouter();

  watch(filters, async (newValue, oldValue) => {
    const query = getQuery(filters.value);
    if (
      filters_utils.methods.isDeepEqual(query, route.query) &&
      query.length > 0
    ) {
      await fetchData();
    } else {
      await router.push({ query });
    }
  });

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, [
      FORMATIONS_FILTERS_CONFIGURATION.date.name,
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);

  const metadataLoaded = ref<boolean>(false);
  const metadatas = ref<FormationMetadata>({
    formations: []
  });
  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    // const response = await API.get(
    //   `client/v2/inscriptions/metadata${activeClientIds.value.length ? '?clientIds=' + activeClientIds.value : ''}`
    // );

    // if (response && response.data) {
    //   metadatas.value = response.data;
    // }
    metadatas.value = {
      formations: [
        {
          id: 1,
          titre: "A attendre l'herbe qui pousse, le boeuf meurt"
        },
        {
          id: 2,
          titre:
            'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet'
        },
        {
          id: 3,
          titre: 'Sous-session du cursus numéro 1'
        },
        {
          id: 4,
          titre: 'Sous-session du cursus numéro 2'
        },
        {
          id: 5,
          titre:
            "Atelier CRCC : L'IA, un assistant virtuel à intégrer dans nos équipes d'audit ?"
        }
      ]
    };
    metadataLoaded.value = true;
  };
</script>

<style lang="scss" scoped></style>
