<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close"
    title="Références internes affichées sur la facture"
    class="!max-w-[800px]">
    <template v-slot:modalBody>
      <div
        v-if="loading"
        class="h-full w-full">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>
      <form
        v-else
        v-on:submit.prevent="submit">
        <div class="flex flex-col gap-[32px]">
          <div>
            <refonte-input
              placeholder="Référence globale de la commande"
              v-bind:value="referenceInterneForm?.referenceInterneClient"
              input_class="w-full"
              v-on:input="setReferenceForm($event, 'referenceInterneClient')"
              v-bind:disabled="disabled"
              v-bind:error="
                v$?.referenceInterneForm?.referenceInterneClient.$error
              " />
            <div
              class="modal-modifier-categorie"
              v-if="referenceInterneForm?.referenceInterneParticipants?.length">
              <span> Référence(s) par participant et par formation</span>
            </div>
            <refonte-input
              v-if="referenceInterneForm?.referenceInterneParticipants?.length"
              v-for="(
                participantItem, participantIndex
              ) in referenceInterneForm?.referenceInterneParticipants"
              v-bind:key="participantIndex"
              v-bind:disabled="disabled"
              v-bind:value="participantItem.referenceInterne"
              v-on:input="setReferenceParticipantForm($event, participantIndex)"
              v-bind:placeholder="`${participantItem.nomPrenom ? participantItem.nomPrenom : 'Réservation'} - ${participantItem.titreFormation}`"
              input_class="w-full" />
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modalFooter>
      <template v-if="disabled">
        <refonte-button
          variant="text"
          v-bind:on_click="close">
          Fermer
        </refonte-button>
      </template>
      <template v-else>
        <refonte-button
          variant="text"
          v-bind:on_click="close">
          Annuler
        </refonte-button>
        <refonte-button v-bind:on_click="submit"> Modifier </refonte-button>
      </template>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import { computed, onMounted, ref } from 'vue';
  import RefonteInput from '@/components/refonte/inputs/refonte-input.vue';
  import { useVuelidate } from '@vuelidate/core';
  import { API } from '@/http-common';
  import { ReferenceInterne } from '@/types/gestion/commandes-types';
  import { maxLength } from '@vuelidate/validators';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit: Function;
      commande_id: number;
      client_id?: number;
      class?: string;
      disabled: boolean;
    }>(),
    {
      class: ''
    }
  );

  const detail = ref(null);
  const loading = ref<boolean>(false);
  const referenceInterneForm = ref<ReferenceInterne>({});

  onMounted(() => {
    fetchDetail();
  });

  const fetchDetail = async () => {
    loading.value = true;
    if (props.client_id && props.commande_id) {
      const response = await API.get(
        `client/${props.client_id}/commande/${props.commande_id}/reference-interne`
      );
      if (response && response.data) {
        referenceInterneForm.value = response.data;
      }
    }
    loading.value = false;
  };

  const rules = computed(() => ({
    referenceInterneForm: {
      referenceInterneClient: {
        maxLength: maxLength(100)
      }
    }
  }));

  const v$ = useVuelidate(rules, { referenceInterneForm });

  function close() {
    detail.value = null;
    props.on_close();
    referenceInterneForm.value = {};
    v$.value.$reset();
  }
  function setReferenceForm(value: string | number | boolean, name: any) {
    referenceInterneForm.value[name] = value;
  }
  function setReferenceParticipantForm(
    value: string | number | boolean,
    name: any
  ) {
    referenceInterneForm.value.referenceInterneParticipants[name] = {
      ...referenceInterneForm.value.referenceInterneParticipants[name],
      referenceInterne: value
    };
  }
  function submit(e: Event) {
    e.preventDefault();
    v$.value.$touch();
    if (!v$.value.$invalid) {
      props.on_submit && props.on_submit(referenceInterneForm.value);
      close();
    }
  }
</script>

<style lang="scss" scoped>
  .modal-modifier-categorie {
    @apply bg-refonte_blue-100;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 16px;

    span {
      @apply text-refonte_blue-600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .modal-modifier-etablissement-table {
    :deep(.refonte-table-row) {
      @apply bg-refonte-pale-violet-2;
    }
  }
</style>
