<template>
  <refonte-filters
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.date) ||
      filters_utils.methods.hasValue(filters.datePeriodeFin) ||
      filters_utils.methods.hasValue(filters.datePeriodeDebut) ||
      filters_utils.methods.hasValue(filters.datePeriodeId) ||
      filters_utils.methods.hasValue(filters.action) ||
      filters_utils.methods.hasValue(filters.formationId)
    ">
    <template v-slot:default>
      <div class="filters-group">
        <refonte-filters-item
          v-bind:on_search="(value) => updateFormationValue(value)"
          v-bind:number="filters.formationId !== undefined ? 1 : undefined">
          Formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-if="formationList.length"
              v-for="formation in formationList"
              v-bind:on_click="
                () =>
                  updateFilter(
                    FORMATIONS_FILTERS_CONFIGURATION.formationId.name,
                    formation.id
                  )
              "
              v-bind:key="formation.id">
              {{ formation.titre }}
            </refonte-dropdown-item>

            <refonte-search-results-empty v-else />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:footer_button_on_click="() => updateDateFilter()"
          v-bind:number="filters.datePeriodeId !== undefined ? 1 : undefined">
          Date de formation
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="periode in PERIOD_IDS"
              v-bind:on_click="
                () => {
                  resetDateInputFilters();
                  updateFilter(
                    FORMATIONS_FILTERS_CONFIGURATION.datePeriodeId.name,
                    periode.id
                  );
                }
              "
              v-bind:key="periode.id">
              {{ periode.label }}
            </refonte-dropdown-item>
            <div class="p-2">
              <refonte-label label="Date" />
              <refonte-datepicker
                v-on:input="(value) => updateDate('date', value)"
                class="p-1"
                placeholder="Date exacte (JJ/MM/YYYY)"
                v-bind:value="date?.date" />
            </div>
            <refonte-separator />
            <div class="p-2">
              <refonte-label label="Période" />
              <refonte-datepicker
                class="p-1"
                placeholder="Début (JJ/MM/YYYY)"
                v-on:input="(value) => updateDate('debut', value)"
                v-bind:value="date?.debut" />
              <refonte-datepicker
                class="p-1"
                placeholder="Fin (JJ/MM/YYYY)"
                v-on:input="(value) => updateDate('fin', value)"
                v-bind:value="date?.fin" />
            </div>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_open="() => resetTypes()"
          v-bind:footer_button_on_click="() => updateTypesFilter()"
          v-bind:footer_button_disabled="
            !(
              temporaryTypes &&
              (temporaryTypes.length !==
                (filters.typeIds ? filters.typeIds.length : 0) ||
                temporaryTypes.sort().join(', ') !==
                  filters.typeIds?.sort().join(', '))
            )
          "
          v-bind:number="filters.typeIds?.length ?? undefined">
          Type
          <template v-slot:dropdownContent>
            <refonte-checkbox
              v-if="typesList.length"
              v-for="t in typesList"
              v-bind:key="t.id"
              v-bind:label="t.label"
              v-bind:value="!!temporaryTypes?.find((type) => type === t.id)"
              v-on:click="(e: Event) => e.stopPropagation()"
              v-on:input="() => updateTypes(t.id)" />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:number="filters.action !== undefined ? 1 : undefined">
          Action à réaliser
          <template v-slot:dropdownContent>
            <refonte-dropdown-item
              v-for="action in ACTIONS"
              v-bind:on_click="
                () =>
                  updateFilter(
                    FORMATIONS_FILTERS_CONFIGURATION.action.name,
                    action.id
                  )
              "
              v-bind:key="action.id">
              {{ action.label }}
            </refonte-dropdown-item>
          </template>
        </refonte-filters-item>
      </div>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () => {
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
                updateFilter(filterApplied.id, undefined);
              updateTemporaryDates(filterApplied.id);
            }
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref } from 'vue';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import filters_utils from '@/mixin/utils/filters_utils';
  import { orderAlphabetically } from '@/components/utils/utils';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import { FACTURES_FILTERS_CONFIGURATION } from '@/stores/factures';
  import {
    FORMATIONS_FILTERS_CONFIGURATION,
    useFormationFilterStore
  } from '@/stores/participant/formations';
  import { FormationMetadata } from '@/types/participant/formations-filters-types';
  import { IdLabel } from '@/types/transverse/details-types';

  const props = withDefaults(
    defineProps<{
      metadatas: FormationMetadata;
    }>(),
    {}
  );

  const formationSearchValue = ref<string>('');
  const updateFormationValue = (value: string) => {
    formationSearchValue.value = value;
  };

  type DateFilter = {
    debut?: Date;
    fin?: Date;
    date?: Date;
    periodeId?: string;
  };

  const updateDate = (name: string, value: undefined | Date | string) => {
    date.value[name] = value;
  };

  const getLabel = (
    list: { id: string | number; label: string }[],
    id: number | string | undefined,
    name: string
  ) => {
    const item = list && list.length && list.find((i) => i.id == id);
    return item ? filters_utils.methods.formatFilter(name, item.label) : '';
  };
  const getProduitTitre = (id: number | undefined) => {
    const item =
      formationFilterList.value &&
      formationFilterList.value.length &&
      formationFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Formation', item.titre)
      : '';
  };
  //TODO à dynamiser
  const typeFilterList = ref<IdLabel[]>([
    { id: 1, label: 'Présentiel' },
    { id: 2, label: 'E-learning' },
    { id: 3, label: 'Webinaire' },
    { id: 4, label: 'Classe virtuelle' },
    { id: 5, label: 'Cursus' },
    { id: 6, label: 'Conférence' }
  ]);

  const typesList = computed(() => typeFilterList.value);

  const ACTIONS = computed(() => [
    {
      id: 'T_POS_A_EFFECTUER',
      label: 'Test de positionnement à effectuer'
    },
    {
      id: 'T_VALIDATION_NON_REALISE',
      label: 'Test de validation des acquis à effectuer'
    },
    {
      id: 'PIF_A_SIGNER',
      label: 'PIF à signer'
    },
    {
      id: 'MODULE_ELEANING_A_DEBUTER',
      label: 'Module e-learning à débuter'
    },
    {
      id: 'MODULE_ELEANING_A_TERMINER',
      label: 'Module e-learning à terminer'
    }
  ]);

  const PERIOD_IDS = computed(() => [
    {
      id: 'ANNEE_EN_COURS',
      label: 'Année en cours'
    },
    {
      id: 'ANNEE_PRECEDENTE',
      label: 'Année précédente'
    }
  ]);
  const getPeriodFilterApply = (id: string | undefined) => {
    const period = PERIOD_IDS.value.find((period) => period.id == id);
    return period ? period.label : '';
  };

  const formationsFilterStore = useFormationFilterStore();
  const { filters } = storeToRefs(formationsFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean
  ) => {
    formationsFilterStore.setFilter(name, value);
  };
  const date = ref<DateFilter>({
    date: filters.value.date,
    debut: filters.value.datePeriodeDebut,
    fin: filters.value.datePeriodeFin,
    periodeId: filters.value.datePeriodeId
  });

  const resetFilters = () => {
    formationsFilterStore.resetFilters();
    const dateFilterDefaut = {
      date: undefined,
      debut: undefined,
      fin: undefined,
      periodeId: undefined
    };
    date.value = { ...dateFilterDefaut };
  };
  const resetDateInputFilters = () => {
    updateFilter(FORMATIONS_FILTERS_CONFIGURATION.date.name, undefined);
    updateFilter(
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      undefined
    );
    updateFilter(
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeFin.name,
      undefined
    );
    date.value = {
      ...date.value,
      date: undefined,
      debut: undefined,
      fin: undefined
    };
  };
  const updateDateFilter = () => {
    const dateDebutValue = date.value;
    updateFilter(
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeId.name,
      undefined
    );
    updateFilter(
      FORMATIONS_FILTERS_CONFIGURATION.date.name,
      dateDebutValue.date
    );
    updateFilter(
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      dateDebutValue.debut
    );
    updateFilter(
      FORMATIONS_FILTERS_CONFIGURATION.datePeriodeFin.name,
      dateDebutValue.fin
    );
  };
  const updateTemporaryDates = (filterId: string) => {
    switch (filterId) {
      case FORMATIONS_FILTERS_CONFIGURATION.date.name:
        updateDate('date', undefined);
        break;
      case FORMATIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name:
        updateDate('debut', undefined);
        break;
      case FORMATIONS_FILTERS_CONFIGURATION.datePeriodeFin.name:
        updateDate('fin', undefined);
        break;
      default:
        break;
    }
  };

  const formatDate = (date: Date | undefined) => {
    return date
      ? format(date.toString(), 'dd/MM/yyyy', {
          locale: fr as unknown as Locale
        })
      : '';
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: FORMATIONS_FILTERS_CONFIGURATION.date.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        formatDate(filters.value.date)
      )
    },
    {
      id: FORMATIONS_FILTERS_CONFIGURATION.datePeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        getPeriodFilterApply(filters.value.datePeriodeId)
      )
    },
    {
      id: FORMATIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Après le
          ${formatDate(filters.value.datePeriodeDebut)}`
      )
    },
    {
      id: FORMATIONS_FILTERS_CONFIGURATION.datePeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Avant le
          ${formatDate(filters.value.datePeriodeFin)}`
      )
    },
    {
      id: FORMATIONS_FILTERS_CONFIGURATION.formationId.name,
      value: getProduitTitre(filters.value.formationId)
    },
    {
      id: FORMATIONS_FILTERS_CONFIGURATION.action.name,
      value: getLabel(ACTIONS.value, filters.value.action, 'Action à réaliser')
    }
  ]);

  const formationFilterList = computed(() => props.metadatas.formations ?? []);

  const formationList = computed(() =>
    formationFilterList.value && formationFilterList.value.length
      ? formationFilterList.value
          .filter(
            (f) =>
              formationSearchValue.value.length < 3 ||
              f.titre
                .toLowerCase()
                .includes(formationSearchValue.value.toLowerCase())
          )
          .sort((f1, f2) => orderAlphabetically(f1.titre, f2.titre))
      : []
  );

  const temporaryTypes = ref<number[] | undefined>(filters.value.typeIds);

  const updateTypesFilter = () => {
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.typeIds.name,
      temporaryTypes.value && temporaryTypes.value.length > 0
        ? temporaryTypes.value
        : undefined
    );
  };
  const resetTypes = () => {
    temporaryTypes.value = filters.value.typeIds;
  };

  const updateTypes = (value: number) => {
    if (temporaryTypes.value?.find((t) => t === value)) {
      temporaryTypes.value = temporaryTypes.value.filter((t) => t !== value);
    } else {
      temporaryTypes.value = temporaryTypes.value
        ? [...temporaryTypes.value, value]
        : [value];
    }
  };

  onMounted(() => {
    resetFilters();
  });
</script>

<style lang="scss" scoped>
  .filters-group {
    @apply flex flex-grow flex-wrap items-center;
    gap: 8px;
  }
</style>
