<template>
  <div class="refonte-layout">
    <side-menu
      v-bind:is_mobile_side_menu_open="isMobileSideMenuOpen"
      v-bind:is_side_menu_reduced="isSideMenuReduced"
      v-bind:toggle_is_mobile_side_menu_open="toggleIsMobileSideMenuOpen"
      v-bind:toggle_is_side_menu_reduced="toggleIsSideMenuReduced" />
    <div class="refonte-content">
      <header-menu
        v-if="activeRubrique"
        v-bind:toggle_is_mobile_side_menu_open="toggleIsMobileSideMenuOpen"
        v-bind:title="
          activeRubrique.headerLabel
            ? activeRubrique.headerLabel
            : activeRubrique.label
        "
        v-bind:sub_title="activeRubrique.enableSubtitle && subLabel"
        v-bind:hide_scope="hideEntrepriseHeader || activeRubrique.hideScope"
        v-bind:icon="ICONS[activeRubrique.icon]" />
      <main
        class="refonte-main"
        v-bind:class="{ 'main-with-side-menu-reduced': isSideMenuReduced }">
        <div class="refonte-main-container">
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
  import headerMenu from '@/components/refonte/header/refonte-header.vue';
  import sideMenu from '@/components/refonte/side-menu/refonte-side-menu.vue';
  import { computed, ref } from 'vue';
  import { ICONS } from '@/store/constants/Icons';
  import { useArborescenceStore } from '@/stores/arborescence';
  import { storeToRefs } from 'pinia';
  import { useRoute } from 'vue-router';
  const arborecenceStore = useArborescenceStore();
  const route = useRoute();
  const hideEntrepriseHeader = computed(
    () => route.params.space_id !== 'gestion'
  );
  const { activeRubrique, subLabel } = storeToRefs(arborecenceStore);
  const isMobileSideMenuOpen = ref<boolean>(false);
  const isSideMenuReduced = ref<boolean>(false);

  function toggleIsMobileSideMenuOpen() {
    isMobileSideMenuOpen.value = !isMobileSideMenuOpen.value;
  }
  function toggleIsSideMenuReduced() {
    isSideMenuReduced.value = !isSideMenuReduced.value;
  }
</script>

<style lang="scss" scoped>
  .refonte-layout {
    @apply flex flex-grow;

    .refonte-content {
      @apply flex flex-grow flex-col;
    }

    .refonte-main {
      @apply h-full w-full flex-1 overflow-y-auto overflow-x-hidden;
      padding: 24px 40px;
      max-width: calc(100vw - 208px);
      max-height: calc(100vh - 80px);

      &.main-with-side-menu-reduced {
        max-width: calc(100vw - 64px);
      }

      @media (max-width: 767.98px) {
        margin-top: 8px;
        padding: 2px 0 10px;
        max-width: 100vw;
        max-height: calc(100vh - 56px - 8px - 56px - 8px);

        &.main-with-side-menu-reduced {
          max-width: 100vw;
        }
      }

      .refonte-main-container {
        @apply mx-auto;
      }
    }
  }
</style>
