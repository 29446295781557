<template>
  <refonte-page-title v-bind:description_in_card="true">
    Bienvenue dans le nouvel Espace Gestion JINIUS.<br /><br />
    Cet espace remplace l'ancien espace entreprise. Il vous permettra de trouver
    plus facilement les informations relatives à vos formations, ainsi que les
    documents associés. <br />Cet écran vous fournit également la liste des
    actions que vous avez à faire.
  </refonte-page-title>
  <refonte-collapse
    class="mt-[12px]"
    v-bind:title_icon="IconFormations"
    title="Inscriptions"
    v-bind:start_open="true">
    <div class="btn-dashbord-container">
      <template v-if="!inscriptionIndicateursLoading">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/gestion/inscriptions?statutId=1')
          ">
          <b>{{ inscriptionIndicateurs.nombreInscriptionsEnAttente }}</b>
          inscription(s) sont en attente de confirmation par l'organisateur.
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/gestion/inscriptions?action=T_POS_NON_REALISE')
          ">
          <b>{{
            inscriptionIndicateurs.nombreInscriptionsTestsPositionnementNonRealise
          }}</b>
          participant(s) n'ont pas encore rempli leur test de positionnement.
          Ceci est nécessaire pour confirmer leur inscription.
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push('/gestion/inscriptions?action=CONVENTIONS_A_SIGNER')
          ">
          <b>{{
            inscriptionIndicateurs.nombreInscriptionsConventionsASigner
          }}</b>
          convention(s) / contrat(s) de formation sont en attente de signature.
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/gestion/inscriptions?action=PIF_A_SIGNER')
          ">
          <b>{{ inscriptionIndicateurs.nombreInscriptionsPifsASigner }}</b>
          PIF (Protocole Individuel de Formation) sont en attente de signature.
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/gestion/inscriptions')">
            Retrouvez vos inscriptions dans le menu Inscriptions.
          </refonte-button>
        </div>
      </template>
      <spinner
        v-else
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    v-bind:title_icon="IconFlagParcours"
    title="Sessions intra"
    v-if="hasSessionsIntra"
    v-bind:start_open="true">
    <div class="btn-dashbord-container">
      <template v-if="!indicateursLoading">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/gestion/sessions?action=CONVENTIONS_A_SIGNER')
          ">
          <b>{{ indicateurs.nombreSessionsIntraConventionsASigner }}</b>
          convention(s) / contrat(s) de formation sont en attente de signature.
        </refonte-button>

        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/gestion/sessions')">
            Retrouvez vos sessions intra dans le menu Sessions intra
          </refonte-button>
        </div>
      </template>
      <spinner
        v-else
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    v-bind:title_icon="IconCommandes"
    title="Commandes"
    v-bind:start_open="true">
    <div class="btn-dashbord-container">
      <template v-if="!indicateursLoading">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/gestion/commandes?commandeReglee=false')
          ">
          <b>{{ indicateurs.nombreCommandesNonReglees }}</b> commande(s) non
          réglée(s).
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/gestion/commandes')">
            Retrouvez vos commandes dans le menu Commandes.
          </refonte-button>
        </div>
      </template>
      <spinner
        v-else
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    v-bind:title_icon="IconTestPositionnement"
    v-if="hasContrats"
    title="Contrats Formateurs"
    v-bind:start_open="true">
    <div class="btn-dashbord-container">
      <template v-if="!indicateursLoading">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => router.push('/gestion/contrats?statutId=1')">
          <b>{{ indicateurs.nombreContratsASigner }}</b> contrat(s) sont en
          attente de signature.
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/gestion/contrats')">
            Retrouvez vos contrats formateurs dans le menu Contrats Formateurs.
          </refonte-button>
        </div>
      </template>
      <spinner
        v-else
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    v-bind:title_icon="IconFactures"
    title="Factures"
    v-bind:start_open="true">
    <div class="btn-dashbord-container">
      <template v-if="!indicateursLoading">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => router.push('/gestion/factures?typeIds=3')">
          Vous avez <b>{{ indicateurs.nombreFacturesARegler }}</b> facture(s)
          non acquittée(s).
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/gestion/factures')">
            Retrouvez toutes vos factures dans le menu Factures.
          </refonte-button>
        </div>
      </template>
      <spinner
        v-else
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </refonte-collapse>
</template>
<script setup lang="ts">
  import RefontePageTitle from '@/components/refonte/titles/refonte-page-title.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconCommandes from '@/assets/img/refonte/icons/menu/icon-orders.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { router } from '@/router';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import IconTestPositionnement from '@/assets/img/refonte/icons/information/icon-test-positionnement.svg?component';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { Entreprise } from '@/types/gestion/entreprise';
  import { useAccueilFilterStore, useAccueilStore } from '@/stores/accueil';
  import { useRoute } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import filters_utils from '@/mixin/utils/filters_utils';

  const indicateursLoading = ref(true);
  const inscriptionIndicateursLoading = ref(true);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds, activeEntrepriseIds, entreprises } =
    storeToRefs(entrepriseStore);
  const accueilStore = useAccueilStore();
  const { indicateurs, inscriptionIndicateurs } = storeToRefs(accueilStore);
  const fetchData = async () => {
    await fetchIndicateursData();
    await fetchInscriptionIndicateursData();
  };

  const fetchIndicateursData = async () => {
    indicateursLoading.value = true;
    let query = {};
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await accueilStore.fetchIndicateurs(query);
    indicateursLoading.value = false;
  };

  const fetchInscriptionIndicateursData = async () => {
    inscriptionIndicateursLoading.value = true;
    let query = {};
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await accueilStore.fetchInscriptionsIndicateurs(query);
    inscriptionIndicateursLoading.value = false;
  };

  const hasContrats = ref<boolean>(false);

  const handleHasContrats = () => {
    if (
      (activeEntrepriseIds.value.length === 0 &&
        (entreprises.value as Entreprise[]).some((e) => e.hasContrats)) ||
      (activeEntrepriseIds.value.length > 0 &&
        (entreprises.value as Entreprise[])
          .filter((e) => activeEntrepriseIds.value.includes(e.entrepriseId))
          .some((e) => e.hasContrats))
    ) {
      hasContrats.value = true;
    } else {
      hasContrats.value = false;
    }
  };

  const hasSessionsIntra = ref<boolean>(false);

  const handleHasSessionsIntra = () => {
    hasSessionsIntra.value =
      (activeEntrepriseIds.value.length === 0 &&
        (entreprises.value as Entreprise[]).some((e) => e.hasSessionsIntra)) ||
      (activeEntrepriseIds.value.length > 0 &&
        (entreprises.value as Entreprise[])
          .filter((e) => activeEntrepriseIds.value.includes(e.entrepriseId))
          .some((e) => e.hasSessionsIntra));
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      if (route.query) {
        await fetchData();
      }
    }
  );
  const accueilFilterStore = useAccueilFilterStore();
  const { filters } = storeToRefs(accueilFilterStore);
  const initFilters = async () => {
    accueilFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    handleHasContrats();
    handleHasSessionsIntra();
    if (!activeClientIds.value) {
      fetchData();
    }
  });

  watch(activeEntrepriseIds, (value) => {
    handleHasContrats();
    handleHasSessionsIntra();
  });

  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, []);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    if (
      filters_utils.methods.isDeepEqual(query, route.query) &&
      query.length > 0
    ) {
      await fetchData();
    } else {
      await router.push({ query });
    }
  });
</script>

<style lang="scss" scoped>
  .btn-dashbord-container {
    @apply flex flex-col;
    margin-bottom: 8px;
    gap: 8px;
    padding: 0 10px;

    .btn-dashboard {
      @apply w-full justify-between text-left text-refonte-black;
      max-width: 100% !important;

      b {
        @apply mr-1 text-refonte_blue-600;
      }
    }
    :deep(.refonte-btn.btn-secondary span) {
      overflow: initial;
      text-overflow: initial;
      display: initial;
      -webkit-box-orient: initial;
      -webkit-line-clamp: initial;
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
    }
  }
</style>
