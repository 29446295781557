<template>
  <div class="p-[8px]">
    <template v-if="commandes.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="commandes-1"
        table_index="commandes-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="commandes" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
  <commandes-modal-reference-internes
    v-if="selectedCommande"
    v-bind:show="isCommandeReferencesInternesModalOpen"
    v-bind:disabled="selectedCommande.statutFacturationId !== 0"
    v-bind:on_close="closeCommandeReferencesInternesModalOpen"
    v-bind:commande_id="selectedCommande.id"
    v-bind:on_submit="modifierReferenceInterne"
    v-bind:client_id="selectedCommande.clientId" />
  <refonte-modal-confirmation-action
    v-bind:show="isConfirmationActionDoneModalOpen"
    v-bind:on_close="closeConfirmationActionDoneModal"
    title="Modifications enregistrées"
    content="Les références internes ont bien été mises à jour." />
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Commande, ReferenceInterne } from '@/types/gestion/commandes-types';
  import { useCommandeStore } from '@/stores/commandes';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import IconSearch from '@/assets/img/refonte/icons/action/icon-search.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { h, ref } from 'vue';
  import CommandesTableItemFormations from '@/views/_espace/_refonte/gestion/_commandes/commandes-table-item-formations.vue';
  import { downloadBonCommande } from '@/mixin/client_download__functions';
  import CommandesModalReferenceInternes from '@/views/_espace/_refonte/gestion/_commandes/commandes-modal-reference-internes.vue';
  import { API } from '@/http-common';
  import RefonteModalConfirmationAction from '@/components/refonte/modals/refonte-modal-confirmation-action.vue';
  import CommandesTableItemPaiementInstruction from '@/views/_espace/_refonte/gestion/_commandes/commandes-table-item-paiement-instruction.vue';
  import { useRouter } from 'vue-router';

  const columns = [
    {
      content_index: 'reference',
      label: 'Référence',
      content_sub_text: (row: Commande) =>
        row.date && row.date !== '-' ? row.date : undefined,
      min_width: 116,
      width: 120
    },
    {
      content_index: 'organisateur',
      label: 'Organisme de formation',
      min_width: 100,
      width: 170
    },
    {
      content_index: 'montantTtc',
      label: 'Montant TTC',
      width: 120
    },
    {
      content_index: 'montantRegle',
      label: 'Réglé',
      min_width: 85,
      width: 200,
      content_custom_with_on_click: (row: Commande) => !row.reglement?.label,
      content_custom: (row: Commande) =>
        h(CommandesTableItemPaiementInstruction, {
          montant_regle: row.montantRegle,
          montant_ttc: row.montantTtc,
          reglement: row.reglement
        })
    },
    {
      content_index: 'payeurLabel',
      label: 'Entité Payeur',
      content_sub_text: (row: Commande) =>
        row.payeurSiret && row.payeurSiret !== '-'
          ? row.payeurSiret
          : undefined,
      min_width: 120,
      width: 200
    },
    {
      content_index: 'formation',
      label: 'Formations',
      content_custom: (row: Commande) =>
        h(CommandesTableItemFormations, {
          formations: row.formations
        }),
      min_width: 240,
      width: 360
    },
    {
      content_index: 'refInternesLabel',
      label: 'Réf. internes',
      min_width: 117,
      width: 130,
      on_click: (row: Commande) => {
        openCommandeReferencesInternesModalOpen(row);
      },
      content_icon: (row: Commande) =>
        row.statutFacturationId === 0 ? IconSearch : IconModify,
      class: () => '!flex-grow-0'
    },
    {
      content_index: 'bonCommandeLabel',
      label: 'Bon de commande',
      min_width: 117,
      width: 130,
      on_click: (row: Commande) => {
        downloadBonCommande(row.clientId, row.id, row.reference);
      },
      content_icon: () => IconDownload,
      class: () => '!flex-grow-0'
    }
  ];

  const commandeStore = useCommandeStore();

  const { commandes } = storeToRefs(commandeStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);

  const isCommandeReferencesInternesModalOpen = ref<boolean>(false);
  const selectedCommande = ref<Commande>();

  function closeCommandeReferencesInternesModalOpen() {
    isCommandeReferencesInternesModalOpen.value = false;
    selectedCommande.value = undefined;
  }
  const openCommandeReferencesInternesModalOpen = async (row: Commande) => {
    isCommandeReferencesInternesModalOpen.value = true;
    selectedCommande.value = row;
  };

  const isConfirmationActionDoneModalOpen = ref<boolean>(false);

  function closeConfirmationActionDoneModal() {
    isConfirmationActionDoneModalOpen.value = false;
  }

  const modifierReferenceInterne = async (
    referenceInternesModifiees: ReferenceInterne
  ) => {
    const clientId = selectedCommande.value?.clientId;
    const commandeId = selectedCommande.value?.id;
    let referenceSessionParticipants = {};
    let referenceElearningParticipants = {};
    let referenceCursusInscriptions = {};

    referenceInternesModifiees.referenceInterneParticipants?.forEach((r) => {
      if (r.referenceInterne) {
        if (r.elearningParticipantId) {
          referenceElearningParticipants = {
            ...referenceElearningParticipants,
            [r.elearningParticipantId]: r.referenceInterne
          };
        } else if (r.cursusInscriptionId) {
          referenceCursusInscriptions = {
            ...referenceCursusInscriptions,
            [r.cursusInscriptionId]: r.referenceInterne
          };
        } else if (r.sessionParticipantId) {
          referenceSessionParticipants = {
            ...referenceSessionParticipants,
            [r.sessionParticipantId]: r.referenceInterne
          };
        }
      }
    });
    const response = await API.put(
      `client/${clientId}/commande/${commandeId}/reference-interne`,
      {
        referenceInterneClient:
          referenceInternesModifiees.referenceInterneClient,
        referenceCursusInscriptions,
        referenceSessionParticipants,
        referenceElearningParticipants
      }
    );
    closeCommandeReferencesInternesModalOpen();
    if (response.status === 200) {
      isConfirmationActionDoneModalOpen.value = true;
    }
  };
  const router = useRouter();
  const goToDetails = (row: Commande) => {
    router.push(`/gestion/commandes-details?reference=${row.reference}`);
  };
</script>

<style lang="scss" scoped></style>
