export const dataElearning = {
    id: 414627,
    support: {
        supports: [
            {
                titre: 'Titre du support 1',
                id: 10617,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: '19.12.23'
            },
            {
                titre: 'Titre du support 2',
                id: 10372,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: 'janv25'
            }
        ]
    },
    catalogueUrl: 'https://catalogue-irf-formega.safir.dev',
    cataloguePubliee: true,
    catalogueReference: '10.01SIN0051.24',
    catalogueHash: '1c60f2f9-1e63-48f3-bbc4-6a723234e569',
    titre: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
    commandeReference: 'C10122400205',
    dateDebut: '20/06/2025',
    dateFin: '25/07/2025',
    dureeMinutes: 420,
    dureeJours: 1.0,
    certifiant: false,
    type: { id: 2, label: 'E-learning' },
    mode: { id: 1, label: 'Inter' },
    organisateur: {
        email: 'formega@formega.com',
        telephone: '04 67 20 98 56',
        nom: 'Test'
    },
    participant: {
        id: 414627,
        nomPrenom: 'DUGUÉ Alice',
        inscription: { id: 2, label: 'Confirmé' },
        positionnement: { label: 'Non requis' },
        validation: { label: 'Réalisé', seuil: '4/5', note: '1/5' },
        satisfaction: { label: 'Non réalisé' },
        etablissement: 'GCEC ENJALBERT & ASSOCIES',
        statut: 'Terminé',
        compteJinius: false,
        attestation: 'Télécharger',
        pif: 'Signé'
    },
    lienElearning: false,
    moduleElearning: {
        dateDebutAcces: '27/03/2025',
        dateFinAcces: '25/06/2025',
        accessible: true,
        showRetractation: false,
        expire: false,
        statutString: 'Non commencé',
        statut: 'N',
        termine: false,
        cours: [
            {
                titre: '1. Accueil',
                score: '0',
                statutString: 'Non commencé',
                statut: 'N'
            },
            {
                titre: '2. Que recouvrent les notions de dividendes, intérêts et redevances ?',
                score: '0',
                statutString: 'Non commencé',
                statut: 'N'
            },
            {
                titre: '3. Dividendes, intérêts et redevances versés par les entreprises françaises',
                score: '0',
                statutString: 'Non commencé',
                statut: 'N'
            },
            {
                titre: '4. Dividendes, intérêts et redevances perçus par les entreprises françaises et leurs obligations déclaratives',
                score: '0',
                statutString: 'Non commencé',
                statut: 'N'
            },
            {
                titre: "Quiz final d'évaluation",
                score: '0',
                statutString: 'Non commencé',
                statut: 'N'
            }
        ]
    },
    commentairesElearning:
        '<p><strong>Attention</strong> : la complétude de votre module e-learning sera prise en compte <strong>sous 24 heures maximum</strong> dans votre espace JINIUS et génèrera votre attestation de fin de formation.<br></p>'
};

export const data = {
    support: {
        supports: [
            {
                titre: 'Titre du support 1',
                id: 10617,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: '19.12.23'
            },
            {
                titre: 'Titre du support 2',
                id: 10372,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: 'janv25'
            }
        ]
    },
    id: 414627,
    lieuNom: 'Montpellier',
    catalogueUrl: 'https://catalogue-irf-formega.safir.dev',
    cataloguePubliee: true,
    catalogueReference: '10.01SIN0051.24',
    catalogueHash: '1c60f2f9-1e63-48f3-bbc4-6a723234e569',
    titre: "A attendre l'herbe qui pousse, le boeuf meurt",
    sessionReference: '10240258',
    dateDebut: '30/06/2025',
    dateFin: '30/06/2025',
    dureeMinutes: 420,
    dureeJours: 1.0,
    certifiant: true,
    type: { id: 1, label: 'Présentiel' },
    mode: { id: 1, label: 'Inter' },
    organisateur: {
        email: 'formega@formega.com',
        telephone: '04 67 20 98 56',
        nom: 'Test'
    },
    participant: {
        id: 414627,
        nomPrenom: 'DUGUÉ Alice',
        inscription: { id: 2, label: 'Confirmé' },
        positionnement: { label: 'Réalisé' },
        validation: { label: 'Réalisé' },
        satisfaction: { label: 'Réalisé' },
        etablissement: 'GCEC ENJALBERT & ASSOCIES',
        compteJinius: false
    },
    programmes: [
        {
            id: 70878,
            titre: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
            date: '30/06/2025',
            prestations: [
                {
                    id: 70878,
                    dureeMinutes: 210,
                    heureDebut: 540,
                    heureFin: 750,
                    date: '30/06/2025',
                    nom: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
                    veille: false,
                    site: {
                        id: 10,
                        denomination: 'TRAJECTOIRE',
                        numeroVoie: '685 RUE LOUIS LEPINE',
                        codePostal: '34935',
                        ville: 'MONTPELLIER CEDEX 9'
                    },
                    typeId: 1
                },
                {
                    id: 70879,
                    dureeMinutes: 210,
                    heureDebut: 840,
                    heureFin: 1050,
                    date: '30/06/2025',
                    nom: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
                    veille: false,
                    site: {
                        id: 10,
                        denomination: 'TRAJECTOIRE',
                        numeroVoie: '685 RUE LOUIS LEPINE',
                        codePostal: '34935',
                        ville: 'MONTPELLIER CEDEX 9'
                    },
                    typeId: 1
                }
            ]
        }
    ],
    dates: [{ id: 81713, date: '30/06/2025', aprem: true, matin: true }],
    lienElearning: false,
    commentairesElearning: ''
};

export const dataWebinaire = {
    support: {
        supports: [
            {
                titre: 'Titre du support 1',
                id: 10617,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: '19.12.23'
            },
            {
                titre: 'Titre du support 2',
                id: 10372,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: 'janv25'
            }
        ]
    },
    id: 414627,
    lieuNom: 'En ligne',
    catalogueUrl: 'https://catalogue-irf-formega.safir.dev',
    cataloguePubliee: true,
    catalogueReference: '10.01SIN0051.24',
    catalogueHash: '1c60f2f9-1e63-48f3-bbc4-6a723234e569',
    titre: "Panorama de l'actualité comptable 2025 (Stage EC)",
    sessionReference: '10240258',
    dateDebut: '30/06/2025',
    dateFin: '30/06/2025',
    dureeMinutes: 420,
    dureeJours: 1.0,
    certifiant: true,
    type: { id: 3, label: 'Webinaire' },
    mode: { id: 1, label: 'Inter' },
    organisateur: {
        email: 'formega@formega.com',
        telephone: '04 67 20 98 56',
        nom: 'Test'
    },
    participant: {
        id: 414627,
        nomPrenom: 'DUGUÉ Alice',
        inscription: { id: 2, label: 'Confirmé' },
        positionnement: { label: 'Non requis' },
        validation: { label: 'Non requis' },
        satisfaction: { label: 'Non réalisé' },
        etablissement: 'GCEC ENJALBERT & ASSOCIES',
        compteJinius: false
    },
    programmes: [
        {
            id: 70878,
            titre: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
            date: '30/06/2025',
            prestations: [
                {
                    id: 70878,
                    dureeMinutes: 210,
                    heureDebut: 540,
                    heureFin: 750,
                    date: '30/06/2025',
                    nom: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
                    veille: false,
                    site: {
                        id: 10,
                        denomination: 'TRAJECTOIRE',
                        numeroVoie: '685 RUE LOUIS LEPINE',
                        codePostal: '34935',
                        ville: 'MONTPELLIER CEDEX 9'
                    },
                    typeId: 1
                },
                {
                    id: 70879,
                    dureeMinutes: 210,
                    heureDebut: 840,
                    heureFin: 1050,
                    date: '30/06/2025',
                    nom: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
                    veille: false,
                    site: {
                        id: 10,
                        denomination: 'TRAJECTOIRE',
                        numeroVoie: '685 RUE LOUIS LEPINE',
                        codePostal: '34935',
                        ville: 'MONTPELLIER CEDEX 9'
                    },
                    typeId: 1
                }
            ]
        }
    ],
    dates: [{ id: 81713, date: '30/06/2025', aprem: true, matin: true }],
    lienElearning: false,
    commentairesElearning: ''
};

export const dataClasseVirtuelle = {
    support: {
        supports: [
            {
                titre: 'Titre du support 1',
                id: 10617,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: '19.12.23'
            },
            {
                titre: 'Titre du support 2',
                id: 10372,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: 'janv25'
            }
        ]
    },
    id: 414627,
    lieuNom: 'En ligne',
    catalogueUrl: 'https://catalogue-irf-formega.safir.dev',
    cataloguePubliee: true,
    catalogueReference: '10.01SIN0051.24',
    catalogueHash: '1c60f2f9-1e63-48f3-bbc4-6a723234e569',
    titre: "Atelier CRCC : L'IA, un assistant virtuel à intégrer dans nos équipes d'audit ?",
    sessionReference: '10240258',
    dateDebut: '30/06/2025',
    dateFin: '30/06/2025',
    dureeMinutes: 420,
    dureeJours: 1.0,
    certifiant: true,
    type: { id: 5, label: 'Classe virtuelle' },
    mode: { id: 1, label: 'Inter' },
    organisateur: {
        email: 'formega@formega.com',
        telephone: '04 67 20 98 56',
        nom: 'Test'
    },
    participant: {
        id: 414627,
        nomPrenom: 'DUGUÉ Alice',
        inscription: { id: 2, label: 'Confirmé' },
        positionnement: { label: 'Non requis' },
        validation: { label: 'Non requis' },
        satisfaction: { label: 'Non réalisé' },
        etablissement: 'GCEC ENJALBERT & ASSOCIES',
        compteJinius: false
    },
    programmes: [
        {
            id: 70878,
            titre: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
            date: '30/06/2025',
            prestations: [
                {
                    id: 70878,
                    dureeMinutes: 210,
                    heureDebut: 540,
                    heureFin: 750,
                    date: '30/06/2025',
                    nom: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
                    veille: false,
                    site: {
                        id: 10,
                        denomination: 'TRAJECTOIRE',
                        numeroVoie: '685 RUE LOUIS LEPINE',
                        codePostal: '34935',
                        ville: 'MONTPELLIER CEDEX 9'
                    },
                    typeId: 1
                },
                {
                    id: 70879,
                    dureeMinutes: 210,
                    heureDebut: 840,
                    heureFin: 1050,
                    date: '30/06/2025',
                    nom: 'Facture électronique : Mise en œuvre pratique du projet au sein du cabinet',
                    veille: false,
                    site: {
                        id: 10,
                        denomination: 'TRAJECTOIRE',
                        numeroVoie: '685 RUE LOUIS LEPINE',
                        codePostal: '34935',
                        ville: 'MONTPELLIER CEDEX 9'
                    },
                    typeId: 1
                }
            ]
        }
    ],
    dates: [{ id: 81713, date: '30/06/2025', aprem: true, matin: true }],
    lienElearning: false,
    commentairesElearning: ''
};

export const dataCursus = {
    support: {
        supports: [
            {
                titre: 'Titre du support 1',
                id: 10617,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: '19.12.23'
            },
            {
                titre: 'Titre du support 2',
                id: 10372,
                dateDisponibilite: '29/03/2026',
                type: 'Documentation',
                dateMiseAJour: '28/03/2025',
                version: 'janv25'
            }
        ]
    },
    id: 414627,
    lieuNom: 'Montpellier',
    catalogueUrl: 'https://catalogue-irf-formega.safir.dev',
    cataloguePubliee: true,
    catalogueReference: '10.01SIN0051.24',
    catalogueHash: '1c60f2f9-1e63-48f3-bbc4-6a723234e569',
    titre: "A attendre l'herbe qui pousse, le boeuf meurt",
    sessionReference: '02250155',
    dateDebut: '30/06/2025',
    dateFin: '30/06/2025',
    dureeMinutes: 4200,
    dureeJours: 90.0,
    certifiant: true,
    type: { id: 8, label: 'Cursus' },
    mode: { id: 1, label: 'Inter' },
    organisateur: {
        email: 'formega@formega.com',
        telephone: '04 67 20 98 56',
        nom: 'Test'
    },
    participant: {
        id: 414627,
        nomPrenom: 'DUGUÉ Alice',
        inscription: { id: 2, label: 'Confirmé' },
        positionnement: { label: 'Réalisé' },
        validation: { label: 'Réalisé' },
        satisfaction: { label: 'Réalisé' },
        etablissement: 'GCEC ENJALBERT & ASSOCIES',
        compteJinius: false
    },
    dates: [{ id: 81713, date: '30/06/2025', aprem: true, matin: true }],
    lienElearning: false,
    commentairesElearning: '',
    formations: [
        {
            inscriptionId: 103797,
            dateDebut: '05/07/2023',
            dateFin: '20/07/2023',
            type: {
                id: 1,
                label: 'Présentiel'
            },
            sessionStatut: {
                id: 3,
                label: 'Confirmée'
            },
            titre: 'Sous-session du cursus numéro 1',
            lieu: 'Abymes',
            inscriptionType: 'SESSION',
            ordre: 1,
            statutLabel: 'En attente'
        },
        {
            inscriptionId: 103798,
            dateDebut: '04/07/2023',
            dateFin: '04/07/2023',
            type: {
                id: 1,
                label: 'Présentiel'
            },
            sessionStatut: {
                id: 2,
                label: 'Ouverte'
            },
            titre: 'Sous-session du cursus numéro 2',
            lieu: 'Abymes',
            inscriptionType: 'SESSION',
            ordre: 2,
            statutLabel: 'Confirmé',
            certificatId: 458390
        }
    ]
};
