<template>
  <div
    class="w-full lg:h-full"
    v-bind:style="[`min-height: var(--wrapper-height)`, cssVars]">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="flex h-full flex-col">
      <template v-if="sessions_list && sessions_list.length">
        <div
          ref="header_height"
          class="flex flex-wrap items-center justify-between sm:mb-8 lg:mb-0 lg:flex-nowrap">
          <h1 class="section__title mt-0 sm:mb-8">
            {{ title }}
          </h1>
          <div
            class="my-2 flex min-h-full w-full flex-wrap items-center justify-end space-y-2 sm:h-16 sm:flex-nowrap lg:space-y-0">
            <transition
              name="fade"
              v-bind:key="is_grid[current_space]"
              mode="out-in">
              <search-input-autocomplete
                v-show="!is_grid[current_space]"
                v-bind:items="itemsToDisplay"
                v-on:setted_result="(e) => fetchAutocompleteValue(e, false)" />
            </transition>

            <div
              v-if="has_sessions"
              class="ml-6 flex items-stretch overflow-hidden rounded-md bg-core-200 text-accent-500 shadow-inner hover:bg-accent-200">
              <button
                v-on:click="toggleIsGrid"
                class="flex w-full items-center justify-center rounded-l px-3 py-1.5 transition"
                v-bind:class="[
                  !is_grid[current_space]
                    ? `bg-${espaces_list[current_space].color} text-white shadow-md`
                    : ''
                ]">
                <font-awesome-icon
                  icon="list"
                  size="lg"
                  fixed-width />
              </button>
              <button
                v-on:click="toggleIsGrid"
                class="flex w-full items-center justify-center rounded-r px-3 py-1.5 transition"
                v-bind:class="[
                  is_grid[current_space]
                    ? `bg-${espaces_list[current_space].color} text-white shadow-md`
                    : ''
                ]">
                <font-awesome-icon
                  icon="table"
                  size="lg"
                  fixed-width />
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!is_grid[current_space]"
          class="mb-5 flex w-full text-xl font-bold">
          Résultats:&nbsp;{{ sessionsToDisplay.length }}
        </div>

        <template v-if="has_sessions">
          <spinner v-if="loading_switch" />

          <template v-else-if="!is_grid[current_space]">
            <inscription-container
              ref="inscription_container"
              v-for="session in sessionsToDisplay"
              v-bind:key="inscriptionContainerKey(session)"
              v-bind:inscription="session"
              v-bind:collapse="session.isParcours || session.isCursus"
              v-bind:produits="session.produits"
              v-bind:espace="current_space"
              v-on:open-positionnement="
                (value) => openPositionnement({ ...value, produit: true })
              " />
          </template>

          <ag-grid-wrapper
            v-else-if="is_grid[current_space]"
            grid_height="calc(var(--wrapper-height) - var(--header-height) - var(--ag-header-height-offset))"
            v-bind:tree_data="{
              headerName: 'Ref.',
              treeData: 'grpRef'
            }"
            v-bind:column_defs="columnDefs"
            v-bind:initial_data="grid_sessions"
            v-bind:exportable="true"
            v-bind:grid_options="gridOptions" />
        </template>

        <template v-else>
          <div class="p-8 text-xl font-bold">Aucun résultat</div>
        </template>

        <template v-if="has_positionnement_modal">
          <positionnement-modal
            v-if="positionnement_modal"
            v-bind:modal_show="positionnement_modal"
            v-bind:onclose="closePositionnementModal"
            v-bind:inscription="inscription" />
        </template>
      </template>
      <template v-else>
        <div class="flex w-full justify-center pt-5 text-lg font-bold">
          {{ no_result }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import inscriptionContainer from '@/components/utils/inscription-container';
  import searchInputAutocomplete from '@/components/utils/search-input-autocomplete.vue';
  import spinner from '@/components/utils/spinner.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';
  import navigation_helpers from '@/mixin/navigation_helpers';
  import positionnementModal from '@/views/_espace/_entreprise/_sessions/_modal/positionnement-modal';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateEspace } = createNamespacedHelpers('espace');
  const { mapMutations: mapMutationsObserver, mapState: mapStateObserver } =
    createNamespacedHelpers('observer');

  export default {
    components: {
      spinner,
      agGridWrapper,
      inscriptionContainer,
      positionnementModal,
      searchInputAutocomplete
    },
    mixins: [navigation_helpers, http_functions, dates_helpers],
    props: {
      title: { type: String },
      no_result: { type: String },
      sessions_list: { type: Array },
      loading: { type: Boolean },
      has_positionnement_modal: { type: Boolean },
      current_space: { type: String },
      is_sessions_terminees: { type: Boolean }
    },
    emits: ['fetch-sessions'],
    data() {
      return {
        header_height: 0,
        loading_switch: false,
        sessionsToDisplay: null,
        inscription: null,
        positionnement_modal: false
      };
    },
    computed: {
      ...mapStateEspace(['espaces_list']),
      ...mapStateObserver({ is_grid: (state) => state.is_grid }),
      cssVars() {
        return { '--header-height': `${this.header_height}px` };
      },
      has_sessions() {
        return this.is_grid[this.current_space]
          ? this.sessions_list && this.sessions_list.length
          : this.sessionsToDisplay && this.sessionsToDisplay.length;
      },
      isFormateur() {
        return this.current_space === 'formateur';
      },
      columnDefs() {
        if (this.has_sessions) {
          const columns_extra_participant =
            this.current_space === 'participant' && !this.is_sessions_terminees
              ? [
                  {
                    headerName: 'Test de positionnement',
                    field: 'testPositionnement',
                    minWidth: 200,
                    cellRenderer: 'positionnementCellRenderer',
                    cellRendererParams: {
                      onclick: (e, params) =>
                        this.openPositionnementFromGrid(params)
                    }
                  },
                  {
                    headerName: 'PIF',
                    field: 'pifDisponible'
                  }
                ]
              : [];

          const columns_extra_participant_sessions_done =
            this.current_space === 'participant' && this.is_sessions_terminees
              ? [
                  {
                    headerName: 'PIF',
                    field: 'pifDisponible'
                  },
                  {
                    headerName: 'Attestation',
                    field: 'attestationDisponible'
                  },
                  {
                    headerName: 'Validation des acquis',
                    field: 'testValidationAcquisDisponible'
                  },
                  {
                    headerName: 'Validation des acquis',
                    field: 'testValidationAcquisRealise'
                  },
                  {
                    headerName: 'Examen',
                    field: 'examenDisponible'
                  },
                  {
                    headerName: 'Examen',
                    field: 'examenRealise'
                  },
                  {
                    headerName: 'Satisfaction',
                    field: 'satisfactionRealise'
                  }
                ]
              : [];

          const columns_extra_entreprise =
            this.current_space === 'entreprise'
              ? [
                  {
                    headerName: 'Convention',
                    field: 'conventionDisponible'
                  },
                  {
                    headerName: 'Convention(s) individuelle(s)',
                    field: 'conventionsIndividuellesDisponible'
                  }
                ]
              : [];

          const columns_extra_formateur = this.isFormateur
            ? [
                {
                  headerName: 'Statut Formateur',
                  field: 'formateurStatut.label',
                  cellRenderer: 'textCenterCellRenderer',
                  minWidth: 200,
                  floatingFilter: true,
                  filter: true
                }
              ]
            : [];

          const columns_fuseau_horaire =
            this.current_space === 'participant' ||
            this.current_space === 'formateur'
              ? [
                  {
                    headerName: 'Fuseau',
                    field: 'fuseauHoraire',
                    cellRenderer: 'textCenterCellRenderer',
                    minWidth: 110,
                    cellRendererParams: { className: 'text-xs' }
                  }
                ]
              : [];

          return [
            {
              headerName: 'Détails',
              field: 'details',
              pinned: 'left',
              minWidth: 150,
              cellRendererParams: (e) => {
                return {
                  labelDisabled: () => {
                    const participantStatut = e.data?.participantStatut;

                    const sessionStatut = e.data?.sessionStatut;

                    return sessionStatut === 'Annulée'
                      ? `Session ${sessionStatut}`
                      : participantStatut;
                  },
                  label: 'Détails',
                  onclick: (e, params) => this.goToDetails(e, params)
                };
              }
            },

            {
              headerName: 'Titre',
              field: 'titre',
              cellRendererParams: {
                onclick: (e, params) => this.goToFiche(e, params)
              }
            },
            {
              headerName: 'Type',
              field: 'type',
              filterValueGetter: (params) => {
                return params.data?.type.label;
              }
            },
            {
              headerName: 'Mode',
              field: 'mode'
            },
            {
              headerName: 'Organisateur',
              field: 'organisateur'
            },
            {
              headerName: 'Date(s)',
              field: 'sessionDates'
            },
            ...columns_fuseau_horaire,
            {
              headerName: 'Lieu',
              field: 'lieu'
            },
            {
              headerName: this.getStatutInfos.label,
              field: this.getStatutInfos.field,
              cellRenderer: 'textCenterCellRenderer'
            },
            {
              headerName: 'Stage',
              field: 'familleStage'
            },
            {
              headerName: 'Formation professionnelle',
              field: 'familleFormationContinue'
            },
            {
              headerName: 'Préparation aux examens',
              field: 'famillePreparationExamen'
            },
            ...columns_extra_participant,
            ...columns_extra_participant_sessions_done,
            ...columns_extra_entreprise,
            ...columns_extra_formateur
          ];
        } else {
          return [];
        }
      },
      gridOptions() {
        return {
          getRowClass: (params) => {
            if (this.isFormateur && params.data?.formateurStatut.id === 3) {
              return 'row-session-cancel';
            }
          }
        };
      },
      grid_sessions() {
        const sub_sessions = [];
        const format_for_grid = this.sessions_list.map((item) => {
          const formated_item = {};

          if (item.classeVirtuelle) {
            item.type = { id: 5, label: 'Classe virtuelle' };
          }

          const isDetails =
            item.sessionStatut && item.sessionStatut !== 'undefined'
              ? item.sessionStatut.id !== 5 && item.accesDetails
              : item.accesDetails;

          formated_item['details'] = isDetails;

          Object.keys(item).forEach((key) => {
            if (
              [
                'mode',
                'commandeStatut',
                'participantStatut',
                'sessionStatut'
              ].includes(key)
            ) {
              formated_item[key] = item[key].label;
            } else if (key === 'type') {
              formated_item[key] = item[key];
              formated_item['typeId'] = item[key].id;
            } else if (
              [
                'attestationDisponible',
                'testValidationAcquisDisponible',
                'examenDisponible',
                'conventionsIndividuellesDisponible'
              ].includes(key)
            ) {
              formated_item[key] = item[key] ? 'Disponible' : 'Indisponible';
            } else if (
              [
                'testValidationAcquisRealise',
                'examenRealise',
                'satisfactionRealise'
              ].includes(key)
            ) {
              formated_item[key] = item[key] ? 'Réalisé' : 'Non réalisé';
            } else if (
              [
                'testPositionnementRealise',
                'testPositionnementDisponible'
              ].includes(key)
            ) {
              formated_item['testPositionnement'] = Object.assign(
                {},
                formated_item['testPositionnement'],
                {
                  [key]: item[key],
                  is_sessions_terminees: this.is_sessions_terminees
                }
              );
            } else if (['conventionDisponible'].includes(key)) {
              formated_item['conventionDisponible'] = item[
                'conventionDisponible'
              ]
                ? 'Signée'
                : item['conventionGeneree']
                  ? 'À signer'
                  : 'Indisponible';
            } else if (['pifDisponible'].includes(key)) {
              formated_item['pifDisponible'] = item['pifDisponible']
                ? 'Signé'
                : item['pifGenere']
                  ? 'À signer'
                  : 'Indisponible';
            } else if (
              [
                'familleStage',
                'familleFormationContinue',
                'famillePreparationExamen'
              ].includes(key)
            ) {
              formated_item[key] = item[key] ? 'Oui' : 'Non';
            } else {
              formated_item[key] = item[key];
            }
          });

          return formated_item;
        });

        format_for_grid.push(...sub_sessions);

        return format_for_grid;
      },
      getStatutInfos() {
        const statut_list = {
          participant: {
            label: 'Statut participant',
            field: 'participantStatut'
          },
          entreprise: {
            label: 'Statut commande',
            field: 'commandeStatut'
          },
          formateur: {
            label: 'Statut session',
            field: 'sessionStatut'
          },
          stagiaire: {
            label: 'Statut participant',
            field: 'participantStatut'
          }
        };

        return (
          statut_list[this.current_space] || {
            label: 'Statut',
            field: 'statut'
          }
        );
      }
    },
    watch: {
      $refs: {
        immediate: true,
        deep: true,
        handler(newValue) {
          setTimeout(() => {
            if (newValue.header_height) {
              this.header_height = newValue.header_height.clientHeight;
            }
          }, 300);
        }
      }
    },
    created() {
      this.fetchAutocompleteValue('', true);
    },
    methods: {
      ...mapMutationsObserver({ setIsGrid: 'setIsGrid' }),
      inscriptionContainerKey(s) {
        return s.id ? s.id : s.commandeId + s.reference;
      },
      toggleIsGrid() {
        this.loading_switch = true;
        this.setIsGrid({
          is_grid: !this.is_grid[this.current_space],
          current_space: this.current_space
        });

        setTimeout(() => {
          this.loading_switch = false;
        }, 300);
      },
      goToFiche(event, params) {
        this.openFiche(event, params);
      },
      goToDetails(event, params) {
        const { reference, commandeId } = params.data;

        if (this.current_space === 'formateur' && reference) {
          this.$router.push(
            `/${this.current_space}/details?reference=${reference}`
          );
        } else if (reference && commandeId) {
          this.$router.push(
            `/${this.current_space}/details?reference=${reference}&commande=${commandeId}`
          );
        }
      },
      fetchSessionsToDisplay(is_first) {
        const format_list = this.sessions_list.map((session) => {
          let produits = [];

          if (session.isParcours) {
            produits =
              !session.isSousProduit &&
              this.sessions_list.filter(
                (s) =>
                  s.parcoursReference === session.reference &&
                  s.commandeReference === session.commandeReference &&
                  s.reference !== session.reference
              );
          }

          if (session.isCursus) {
            produits =
              !session.isSousProduit &&
              this.sessions_list.filter(
                (s) =>
                  s.cursusReference === session.reference &&
                  s.commandeReference === session.commandeReference &&
                  s.reference !== session.reference
              );
          }

          return {
            ...session,
            produits
          };
        });

        this.sessionsToDisplay = format_list.filter(
          (session) => !session.isSousProduit
        );
        if (is_first) {
          this.itemsToDisplay = this.sessionsToDisplay;
        }
      },
      fetchAutocompleteValue(search, is_first) {
        const fetchSearch = (session) => {
          if (search.type) {
            return search.value.some(
              (e) => e[search.type] == session[search.type]
            );
          } else if (typeof search.value == 'object' && !search.type) {
            return ['titre', 'reference', 'organisateur', 'lieu'].every(
              (type) => search.value[type] === session[type]
            );
          } else {
            return true;
          }
        };

        if (this.sessions_list) {
          this.fetchSessionsToDisplay(is_first);
          this.sessionsToDisplay = this.sessionsToDisplay.filter(fetchSearch);
        }

        if (this.$refs.inscription_container) {
          this.$refs.inscription_container.forEach((el) => {
            if (el.collapse) {
              el.openCollapse();
            }
          });
        }

        const highlight_list = document.getElementsByClassName('highlight');

        [...highlight_list].forEach((item) => {
          item.replaceWith(...item.childNodes);
        });

        if (search && search.type && search.search_string) {
          const data_id = `[data_id='${search.type}']`;

          this.$nextTick(() => {
            const sanitizeHTML = function (str) {
              const temp = document.createElement('div');

              temp.textContent = str;

              return temp.innerHTML;
            };
            const escapeRegExp = (str) =>
              str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            const elems = document.querySelectorAll(data_id);
            const elems_list = [...elems].filter((el) => {
              const regex = new RegExp(
                escapeRegExp(search.search_string),
                'gi'
              );

              return regex.test(el.innerText);
            });

            elems_list.forEach((node) => {
              const result = node.textContent;

              const index = result
                .toLowerCase()
                .indexOf(search.search_string.toLowerCase());
              const last_index = index + search.search_string.length;
              const slice = result.slice(index, last_index);

              const replace_match = new RegExp(
                escapeRegExp(search.search_string),
                'gi'
              );
              const replacer = `<span class="highlight">${sanitizeHTML(slice)}</span>`;

              node.innerHTML = node.innerHTML.replace(replace_match, replacer);
            });
          });
        }
      },
      closePositionnementModal() {
        this.$emit('fetch-sessions');
        this.positionnement_modal = false;
      },
      openPositionnementFromGrid(params) {
        this.openPositionnement(params.data);
      },
      async openPositionnement(value) {
        this.inscription = value;
        this.positionnement_modal = true;
      }
    }
  };
</script>

<style lang="scss" scoped>
  :deep(.highlight) {
    @apply bg-yellow-300;
  }
  :deep(.row-session-cancel) {
    @apply italic text-jinius-red #{!important};

    .default-renderer {
      @apply italic text-jinius-red #{!important};
    }
  }
</style>
