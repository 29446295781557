<template>
  <div
    class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
    <div
      class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
      <refonte-table-number
        v-bind:number="props.session_count?.nbSessions"
        v-bind:label="
          props.session_count?.nbSessions > 1 ? 'Sessions' : 'Session'
        " />
      <IconSeparatorVertical />
      <refonte-table-number
        v-bind:number="props.session_count?.nbConventionsASigner"
        v-bind:label="`${
          props.session_count?.nbConventionsASigner > 1
            ? 'Conventions'
            : 'Convention'
        } à signer`"
        v-bind:on_click="
          () => {
            sessionFilterStore.setFilter(
              SESSIONS_FILTERS_CONFIGURATION.action.name,
              'CONVENTIONS_A_SIGNER'
            );
          }
        " />
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import {
    SessionCount,
    SESSIONS_FILTERS_CONFIGURATION,
    useSessionFilterStore
  } from '@/stores/sessions';

  const props = withDefaults(
    defineProps<{
      session_count?: SessionCount;
      count_loaded?: boolean;
    }>(),
    {
      count_loaded: false
    }
  );

  const sessionFilterStore = useSessionFilterStore();
</script>

<style lang="scss" scoped></style>
