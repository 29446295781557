<template>
  <div
    class="table-item-documents"
    title=" "
    v-on:click="
      (e) => {
        e.stopPropagation();
      }
    ">
    <div v-if="props.row.sessionStatutLabel !== 'Annulé'">
      <div
        v-if="props.row.testPositionnementLabel"
        class="table-item-document"
        v-on:click="async () => {}">
        <div class="table-item-document-icon">
          <IconModify />
        </div>
        <span class="table-item-document-text">
          {{ props.row.testPositionnementLabel }}
        </span>
      </div>
      <div
        v-if="props.row.testValidationsAcquiLabel"
        class="table-item-document"
        v-on:click="async () => {}">
        <div class="table-item-document-icon">
          <div class="table-item-document-icon">
            <IconModify />
          </div>
        </div>
        <span class="table-item-document-text">
          {{ props.row.testValidationsAcquiLabel }}
        </span>
      </div>
      <div
        v-if="props.row.satisfactionLabel"
        class="table-item-document"
        v-on:click="async () => {}">
        <div class="table-item-document-icon">
          <div class="table-item-document-icon">
            <IconModify />
          </div>
        </div>
        <span class="table-item-document-text">
          {{ props.row.satisfactionLabel }}
        </span>
      </div>
      <div
        v-if="props.row.moduleElearningLabel"
        class="table-item-document"
        v-on:click="async () => {}">
        <div class="table-item-e-learning-icon-container">
          <div class="table-item-e-learning-icon">
            <IconOpenNewTab />
          </div>
        </div>
        <span class="table-item-document-text">
          {{ props.row.moduleElearningLabel }}
        </span>
      </div>
      <div
        v-if="
          !props.row.satisfactionLabel &&
          !props.row.testValidationsAcquiLabel &&
          !props.row.testPositionnementLabel &&
          !props.row.moduleElearningLabel
        "
        class="table-item-document">
        <span class="table-item-document-text !text-refonte_blue-200"> - </span>
      </div>
    </div>
    <div
      v-else
      class="table-item-document">
      <span class="table-item-document-text !text-refonte_blue-200"> - </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Formation } from '@/types/participant/formations-types';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import IconOpenNewTab from '@/assets/img/refonte/icons/action/icon-open-new-tab.svg?component';
  const props = withDefaults(
    defineProps<{
      row: Formation;
      refresh_rows: Function;
    }>(),
    {}
  );
</script>

<style lang="scss" scoped>
  .table-item-documents {
    @apply flex flex-col;
    gap: 6px;

    .table-item-document {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-document-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }
      }
      .table-item-document-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
      &:hover {
        .table-item-document-text {
          @apply underline;
        }
      }

      &.table-item-document-to-sign {
        .table-item-document-icon {
          :deep(svg) {
            @apply text-refonte-secondary;
          }
        }
        .table-item-document-text {
          @apply text-refonte-secondary;
        }
      }

      .table-item-e-learning-icon-container {
        @apply flex items-center justify-center;
        width: 20px;
        height: 20px;
        .table-item-e-learning-icon {
          @apply flex shrink-0 items-center justify-center;
          width: 16px;
          height: 16px;

          :deep(svg) {
            @apply text-refonte_blue-200;
          }
        }
      }
    }
  }
</style>
