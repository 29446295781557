<template>
  <template v-if="!loading && detail">
    <formations-details-main v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Synthèse"
      v-bind:start_open="true">
      <template v-if="detail.participant">
        <refonte-table
          key="participant-1"
          table_index="participant-1"
          v-bind:columns="columnsParticipant"
          v-bind:data="[detail.participant]">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.commentairesElearning"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Informations importantes"
      v-bind:start_open="true">
      <div class="p-2">
        Particularités du module e-learning préalable aux sessions en présentiel
      </div>
      <div
        class="p-2"
        v-html="detail.commentairesElearning" />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.formations && detail.formations.length"
      class="mt-[12px]"
      v-bind:title_icon="IconFormations"
      title="Modules"
      v-bind:start_open="true">
      <template v-if="detail.formations.length">
        <refonte-table
          v-bind:on_details="() => {}"
          key="formations-cursus-1"
          table_index="formations-cursus-1"
          v-bind:columns="columnsFormations"
          v-bind:data="detail.formations.map((f) => formatFormation(f))">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Documents"
      v-bind:start_open="true">
      <div class="flex flex-wrap gap-[8px] pb-[12px]">
        <refonte-item-file
          id="convocation"
          title="Convocation"
          v-bind:on_click_download="() => {}"
          v-bind:no_signature_needed="true" />
        <refonte-item-file
          id="attestation"
          title="Attestation"
          v-bind:on_click_download="() => {}"
          v-bind:no_signature_needed="true" />
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="
        detail.titre &&
        detail?.support?.supports &&
        detail?.support?.supports?.length > 0
      "
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Supports pédagogiques"
      v-bind:start_open="true">
      <template v-if="detail.support && detail?.support.supports.length > 0">
        <refonte-table
          key="supports-1"
          table_index="supports-1"
          v-bind:columns="columnsSupports"
          v-bind:data="detail?.support?.supports">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>

    <refonte-collapse
      v-if="detail.titre && detail.programmes && detail.programmes.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconDayAgenda"
      title="Détails de la programmation">
      <template v-if="detail.programmes.length">
        <div
          v-for="(programme, index) in detail.programmes"
          v-bind:key="`programme-${index}`">
          <refonte-separator
            v-if="index > 0 && detail.programmes.length > 1"
            class="px-[12px]" />
          <refonte-label
            v-if="programme.titre && detail.programmes.length > 1"
            v-bind:label="programme.titre"
            class="px-[16px]" />
          <refonte-table
            v-if="programme?.prestations && programme?.prestations?.length > 0"
            key="prestations-1"
            table_index="prestations-1"
            v-bind:columns="columnsPrestations"
            v-bind:data="
              programme?.prestations?.map((p: Prestation) => ({
                ...p,
                nom: p.nom ? p.nom : p.typeId == 2 ? 'Repas' : p.titre,
                lieuNom: p.site ? p.site.denomination : undefined
              }))
            ">
          </refonte-table>
        </div>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, h, onMounted, ref, watch } from 'vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconDayAgenda from '@/assets/img/refonte/icons/information/icon-day-agenda.svg?component';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import IconValidate20Green from '@/assets/img/refonte/icons/information/icon-validate-20-green.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteTable, {
    Column
  } from '@/components/refonte/tables/refonte-table.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import { useRoute } from 'vue-router';
  import FormationsDetailsMain from '@/views/_espace/_refonte/participant/_formations-details/formations-details-main.vue';
  import {
    data,
    dataClasseVirtuelle,
    dataCursus,
    dataWebinaire
  } from '@/stores/data/formations-details-data';
  import {
    FormationsDetail,
    Participant,
    Prestation
  } from '@/types/participant/formations-details-types';
  import { Inscription } from '@/types/gestion/inscriptions-types';
  import {
    buildProduitTypeLabel,
    buildProduitTypeShortcut
  } from '@/stores/utils';
  import { Facture } from '@/types/gestion/factures-types';

  const detail = ref<FormationsDetail>();
  const route = useRoute();
  const loading = ref<boolean>(false);
  const id = computed(() => route.query.id);
  // const produitId = computed(() => route.query.produitId);

  watch(
    () => route.query.id,
    () => fetchFormationDetail()
  );

  const fetchFormationDetail = async () => {
    loading.value = true;

    /* if (id.value) {
      const response = await API.get(
        `client/v2/inscriptions/session/${id.value}/details${produitId.value ? `?produitId=${produitId.value}` : ''}`
      );

      if (response && response.data) {
        detail.value = mapInscriptionDetail(response.data);
      }
    }*/
    if (id.value == '3') {
      detail.value = mapFormationDetail(dataWebinaire);
    } else if (id.value == '5') {
      detail.value = mapFormationDetail(dataClasseVirtuelle);
    } else if (id.value == '1001') {
      detail.value = mapFormationDetail(dataCursus);
    } else {
      detail.value = mapFormationDetail(data);
    }

    loading.value = false;
  };

  const mapFormationDetail = (detail: FormationsDetail) => {
    return {
      ...detail,
      support: {
        ...detail.support,
        supports: detail?.support?.supports.map((s) => mapSupport(s))
      }
    };
  };
  const mapSupport = (support) => {
    return {
      ...support,
      supportLabel: 'Télécharger'
    };
  };
  onMounted(async () => {
    await fetchFormationDetail();
  });

  const columnsPrestations: Column[] = [
    {
      content_index: 'nom',
      date: 'date',
      width: 308
    },
    {
      content_index: 'heureDebut',
      label: 'Heure de début',
      width: 120,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(row.heureDebut)
        })
    },
    {
      content_index: 'heureFin',
      label: 'Heure de fin',
      width: 120,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(row.heureFin)
        })
    },
    {
      content_index: 'dureeMinutes',
      label: 'Durée',
      width: 90,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: row.dureeMinutes
            ? dates_helpers.methods.formatMinutesToHours(row.dureeMinutes)
            : '-'
        })
    },
    {
      content_index: 'lieuNom',
      label: 'Site',
      width: 150
    }
  ];

  const getParticipantInscriptionStatus = (status: string) => {
    switch (status) {
      case 'En attente':
        return 'En attente';
      case 'Confirmé':
        return 'Confirmée';
      case 'Annulé':
        return 'Annulée';
      case 'Transférée':
        return 'Annulée';
      default:
        return '-';
    }
  };

  const columnsParticipant = computed(() => [
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    },
    {
      content_index: 'positionnement',
      label: 'T. Positionnement',
      width: 120,
      content_icon: (row: Participant) => IconValidate20Green,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.positionnement ? row.positionnement.label : '-'
        })
    },
    {
      content_index: 'validation',
      label: 'T. valid. acquis',
      width: 120,
      content_icon: (row: Participant) => IconValidate20Green,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.validation ? row.validation.label : 'Non requis'
        })
    },

    {
      content_index: 'satisfaction',
      label: 'Satisfaction',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        }),
      content_icon: (row: Participant) => IconValidate20Green
    },
    ...(detail.value?.lienElearning || detail.value?.type.id == 2
      ? [
          {
            content_index: 'elearning',
            label: 'E-learning',
            width: 140,
            content_custom: (row: Participant) =>
              h('span', {
                innerHTML: row.elearning ? row.elearning.label : '-'
              })
          }
        ]
      : [])
  ]);
  const columnsSupports = [
    {
      content_index: 'titre',
      label: 'Titre',
      width: 150
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 150
    },
    {
      content_index: 'version',
      label: 'Version',
      width: 150
    },
    {
      content_index: 'dateMiseAJour',
      label: 'Mise à jour',
      width: 150
    },
    {
      content_index: 'dateDisponibilite',
      label: "Disponible jusqu'au",
      width: 150
    },
    {
      content_index: 'supportLabel',
      label: 'Support',
      min_width: 140,
      width: 170,
      on_click: (row: Facture) => {},
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];

  const formatFormation = (f) => {
    let dates = '-';
    if (f.dateDebut && f.dateFin) {
      if (f.dateDebut == f.dateFin) {
        dates = `${f.dateDebut}`;
      } else {
        dates = `${f.dateDebut} - ${f.dateFin}`;
      }
    }
    return {
      ...f,
      datesFormations: dates,
      disabled: f.sessionStatut?.id === 5,
      typeShortcut: addSessionTypeShortcut(
        buildProduitTypeShortcut(f.type.id, f.lienElearning),
        f.sessionStatut?.id
      ),
      typeLabel: addSessionTypeLabel(
        buildProduitTypeLabel(f.type.label, f.lienElearning),
        f.sessionStatut?.id
      )
    };
  };

  const addSessionTypeLabel = (flags: string[], sessionStatutId: number) => {
    return sessionStatutId === 5 ? [...flags, 'Annulée'] : flags;
  };

  const addSessionTypeShortcut = (flags: string[], sessionStatutId: number) => {
    return sessionStatutId === 5 ? [...flags, 'annulee'] : flags;
  };

  const columnsFormations = [
    {
      content_index: 'titre',
      tags_type: 'typeShortcut',
      tags_type_label: 'typeLabel',
      content_sub_text: (row: Inscription) =>
        `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu ?? ''}`,
      width: 308
    },
    {
      content_index: 'datesFormations',
      label: 'Dates de formation',
      width: 190
    },
    {
      content_index: 'statutLabel',
      label: 'Statut',
      width: 85,
      content_custom: (row: Inscription) =>
        h('span', {
          innerHTML: row.statutLabel,
          class:
            row.statutLabel === 'Annulé' || row.statutLabel === 'Transféré'
              ? 'text-jinius-red'
              : ''
        })
    },
    {
      content_index: 'lieu',
      label: 'Site',
      width: 85
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
