<template>
  <div
    v-if="profile.activeEntrepriseId"
    class="entreprise-dropdown">
    <div
      v-on-click-outside="closeOpener"
      v-on:click="toggleOpener"
      class="entreprise-dropdown__button"
      v-bind:class="[
        { 'entreprise-dropdown__button--has-list': entrepriseList.length > 0 },
        {
          'entreprise-dropdown__button--open':
            opener && entrepriseList.length > 0
        }
      ]">
      <span class="button__label">
        <text-button
          icon="building"
          size="lg"
          width="2rem"
          height="2rem"
          label_margin="4"
          v-bind:label="getEntrepriseLabel(profile.activeEntrepriseId).label"
          v-bind:description="
            getEntrepriseLabel(profile.activeEntrepriseId).actif === false
              ? '(Entité désactivée)'
              : getEntrepriseLabel(profile.activeEntrepriseId).siret
                ? `Siret: ${getEntrepriseLabel(profile.activeEntrepriseId).siret}`
                : null
          "
          v-bind:class="[
            { active: opener },
            {
              'text-link__desactivee':
                getEntrepriseLabel(profile.activeEntrepriseId).actif === false
            }
          ]">
          <div
            v-if="displayEntrepriseRole(profile.activeEntrepriseId)"
            class="button__label--status-info"
            v-bind:class="`text-${displayEntrepriseRole(profile.activeEntrepriseId).color}`">
            {{ displayEntrepriseRole(profile.activeEntrepriseId).label }}
          </div>
        </text-button>
      </span>

      <span
        class="button__caret"
        v-if="entrepriseList && entrepriseList.length > 0">
        <svg
          class="caret__svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20">
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </span>
    </div>

    <div class="entreprise-dropdown__list">
      <transition name="slide-up">
        <div
          v-if="opener"
          class="list__wrapper">
          <template
            v-for="entreprise in entrepriseList"
            v-bind:key="entreprise.entrepriseId">
            <div
              class="list__item"
              v-on:click="selectEntreprise(entreprise)">
              <text-button
                v-bind:label="`${entreprise.denomination} (${entreprise.ville}, ${entreprise.codePostal})`"
                v-bind:description="
                  entreprise.actif === false
                    ? '(Entité désactivée)'
                    : entreprise.siret
                      ? `Siret: ${entreprise.siret}`
                      : entreprise.ville
                "
                v-bind:class="{
                  'text-link__desactivee': entreprise.actif === false
                }"
                size="lg"
                icon="building"
                width="2rem"
                height="2rem"
                label_margin="4"
                class="item__link">
                <div
                  v-if="displayEntrepriseRole(entreprise.entrepriseId)"
                  class="item__link--status-info"
                  v-bind:class="`text-${displayEntrepriseRole(entreprise.entrepriseId).color}`">
                  {{ displayEntrepriseRole(entreprise.entrepriseId).label }}
                </div>
              </text-button>
            </div>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
  import textButton from '@/components/utils/text-button.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { vOnClickOutside } from '@vueuse/components';
  import { createNamespacedHelpers } from 'vuex';
</script>
<script>
  const { mapState: mapStateAuth, mapGetters: mapGettersAuth } =
    createNamespacedHelpers('auth');

  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  const { mapActions: mapActionsEspace } = createNamespacedHelpers('espace');

  export default {
    components: { textButton },
    data() {
      return { opener: false };
    },
    computed: {
      ...mapStateClient(['profile']),
      ...mapStateAuth({
        user: (state) => state.user,
        authenticated: (state) => state.authenticated
      }),
      ...mapGettersAuth(['username']),
      ...mapStateObserver({ scrolled: (state) => state.scrolled }),
      entrepriseList() {
        const { activeEntrepriseId } = this.profile;

        return (
          this.profile.entreprises &&
          this.profile.entreprises
            .filter(
              (entreprise) => entreprise.entrepriseId !== activeEntrepriseId
            )
            .sort((a, b) => b.actif - a.actif)
        );
      }
    },
    methods: {
      ...mapActionsClient([ACTION_TYPES.SET_ACTIVE_ENTREPRISE]),
      ...mapActionsEspace([ACTION_TYPES.SET_RUBRIQUE]),
      closeOpener() {
        this.opener = false;
      },
      toggleOpener() {
        if (this.entrepriseList.length > 0) {
          this.opener = !this.opener;
        }
      },
      getEntrepriseLabel(id) {
        const activeEntreprise = this.profile.entreprises.find(
          (entreprise) => entreprise.entrepriseId === id
        );

        return activeEntreprise
          ? {
              label: `${activeEntreprise.denomination} (${activeEntreprise.ville}, ${activeEntreprise.codePostal})`,
              siret: activeEntreprise.siret,
              actif: activeEntreprise.actif
            }
          : '';
      },
      displayEntrepriseRole(id) {
        const e = this.profile.entreprises.find(
          (entreprise) => entreprise.entrepriseId === id
        );

        if (e.droitAdministrateur) {
          return {
            id: `${e.entrepriseId}-droit-admin`,
            label: `Droit Administrateur ${!e.statutAdminValide ? '(En attente)' : ''}`,
            color: 'jinius-dark-green'
          };
        } else if (
          !e.droitAdministrateur &&
          e.droitInscription &&
          !e.droitAccesDocuments
        ) {
          return {
            id: `${e.entrepriseId}-droit-inscription`,
            label: `Droit d'inscription ${!e.statutAdminValide ? '(En attente)' : ''}`,
            color: 'jinius-blue'
          };
        } else if (e.droitAccesDocuments) {
          return {
            id: `${e.entrepriseId}-droit-inscription`,
            label: `Droit d'accés au documents ${e.droitInscription ? "(Avec droit d'inscription)" : ''}`,
            color: 'jinius-green'
          };
        }
      },
      selectEntreprise(entreprise) {
        this.opener = false;
        const activeEntreprise = this.profile.entreprises.find(
          (e) => e.entrepriseId === this.profile.activeEntrepriseId
        );

        if (
          ((activeEntreprise.droitAdministrateur !==
            entreprise.droitAdministrateur ||
            activeEntreprise.droitInscription !== entreprise.droitInscription ||
            activeEntreprise.droitAccesDocuments !==
              entreprise.droitAccesDocuments) &&
            this.$route.params.sub_space_id === 'entreprise' &&
            this.$route.params.space_id === 'entreprise') ||
          this.$route.params.sub_space_id === 'details'
        ) {
          this.$router.push('/entreprise');
        }

        if (
          activeEntreprise.hasContrats &&
          this.$route.params.sub_space_id === 'contrats'
        ) {
          this.$router.push('/entreprise');
        }

        if (
          activeEntreprise.hasSessionsIntra &&
          this.$route.params.sub_space_id === 'sessions'
        ) {
          this.$router.push('/entreprise');
        }

        this[ACTION_TYPES.SET_ACTIVE_ENTREPRISE]({
          id: entreprise.entrepriseId
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .entreprise-dropdown {
    @apply flex h-full w-full max-w-md flex-wrap rounded-lg border border-core-300 bg-white text-xs shadow-sm;

    .entreprise-dropdown__button {
      @apply flex h-full w-full px-3 transition duration-200 ease-in-out;

      &.entreprise-dropdown__button--has-list {
        @apply cursor-pointer hover:text-core-900 hover:shadow-md;
      }
      &.entreprise-dropdown__button--open {
        @apply shadow-inner;
      }

      .button__label {
        @apply flex w-[calc(100%-1rem)] cursor-pointer items-center;

        :deep(.text-link__name.text-link__name--responsive) {
          // On annule la disparition du label en vue mobile
          @apply flex;
        }
        :deep(.button__label--status-info) {
          @apply w-full px-4 pt-1 text-right text-xs lowercase leading-none lg:pt-0;
          &:first-letter {
            @apply capitalize;
          }
        }
      }

      .button__caret {
        @apply pointer-events-none flex w-6 items-center justify-center;

        .caret__svg {
          @apply flex h-6 w-6 fill-current;
        }
      }
    }

    .entreprise-dropdown__list {
      @apply relative z-50 h-0 w-full;

      .list__wrapper {
        @apply absolute left-0 right-0 top-0 z-20 mt-1 flex max-h-120 min-w-[250px] flex-wrap space-y-1 overflow-auto rounded-lg bg-core-100 font-normal text-core-600 shadow;

        .list__item {
          @apply flex h-16 w-full flex-wrap hover:bg-accent-200 hover:text-core-900 lg:h-20;

          :deep(.item__link) {
            @apply w-full cursor-pointer px-4 py-1 transition duration-150 ease-in-out active:shadow-inner;
            .item__link--status-info {
              @apply w-full pt-0.5 text-right text-xs lowercase leading-none;
              &:first-letter {
                @apply capitalize;
              }
            }
          }
        }
      }
    }
  }

  :deep(.text-link) {
    @apply justify-start;

    .fill-current,
    .text-link__name {
      @apply flex-1 pr-3;
      color: inherit;
    }
  }
  .text-link__desactivee {
    :deep(.text-link__name) {
      @apply text-jinius-red;
    }
    :deep(.text-link__description) {
      @apply font-bold normal-case text-jinius-red;
    }
  }
</style>
