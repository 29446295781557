<template>
  <div
    class="table-item-col"
    v-if="props.row">
    <div class="table-item-row">
      <div>
        <span>{{
          props.row.validation ? props.row.validation.label : 'Non requis'
        }}</span>
      </div>
    </div>
    <div
      class="table-item-row"
      v-if="props.row.validation?.note">
      <div class="card note">
        <span>Note : {{ props.row.validation.note }}</span>
      </div>
      <div
        class="card"
        v-if="props.row.validation?.seuil">
        <span>Seuil : {{ props.row.validation.seuil }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Participant } from '@/types/participant/formations-details-types';

  const props = withDefaults(
    defineProps<{
      row: Participant;
    }>(),
    {}
  );
</script>

<style lang="scss" scoped>
  .table-item-col {
    @apply flex flex-col;
    gap: 8px;
  }
  .table-item-row {
    @apply flex items-center;
    gap: 8px;
  }
  .card {
    @apply bg-accent-500 text-core-100;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 400;
  }
  .note {
    @apply bg-refonte_blue-600;
  }
</style>
