<template>
  <div class="p-[8px]">
    <template v-if="satisfaction.length">
      <refonte-table
        key="satisfaction-1"
        table_index="satisfaction-1"
        v-bind:on_action_click="openSatisfactionModal"
        v-bind:on_action_disabled="(row: Satisfaction) => row.dateReponse"
        on_action_variant="success"
        v-bind:on_action_label="
          (row: Satisfaction) => (row.dateReponse ? 'Répondu' : 'Répondre')
        "
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="satisfaction" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
  <satisfaction-modal
    v-if="isSatisfactionModalOpen"
    v-bind:show="isSatisfactionModalOpen"
    v-bind:on_close="closeSatisfactionModal"
    v-bind:on_success="openConfirmationActionDoneModal"
    v-bind:satisfaction="selectedSatisfaction" />
  <refonte-modal-confirmation-action
    v-bind:show="isConfirmationActionDoneModalOpen"
    v-bind:on_close="closeConfirmationActionDoneModal"
    title="Questionnaire de satisfaction"
    content="Vos réponses ont été enregistrées avec succès." />
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useSatisfactionStore } from '@/stores/satisfaction';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { Satisfaction } from '@/types/gestion/satisfaction-types';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import satisfactionModal from '@/views/_espace/_refonte/gestion/_satisfaction/satisfaction-modal.vue';

  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import RefonteModalConfirmationAction from '@/components/refonte/modals/refonte-modal-confirmation-action.vue';

  const columns = [
    {
      content_index: 'dateQuestionnaire',
      label: 'Date questionnaire',
      min_width: 105,
      width: 140
    },
    {
      content_index: 'organismeNom',
      label: 'Organisme de formation',
      min_width: 145,
      width: 175
    },
    {
      content_index: 'formation',
      label: 'Formation',
      min_width: 240,
      width: 350
    },
    {
      content_index: 'dateReponse',
      label: 'Répondu le',
      min_width: 96,
      width: 110
    },
    {
      content_index: 'reponduPar',
      label: 'Répondu par',
      width: 180
    }
  ];

  const satisfactionStore = useSatisfactionStore();

  const { satisfaction } = storeToRefs(satisfactionStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);

  const route = useRoute();

  const isSatisfactionModalOpen = ref<boolean>(false);
  const isConfirmationActionDoneModalOpen = ref<boolean>(false);
  const selectedSatisfaction = ref<Satisfaction>();
  const dataLoading = ref<boolean>(false);

  const closeSatisfactionModal = () => {
    isSatisfactionModalOpen.value = false;
  };
  const openSatisfactionModal = (row: Satisfaction) => {
    selectedSatisfaction.value = row;
    isSatisfactionModalOpen.value = true;
  };
  const closeConfirmationActionDoneModal = async () => {
    isConfirmationActionDoneModalOpen.value = false;
    await fetchSatisfactions();
  };
  const openConfirmationActionDoneModal = () => {
    isConfirmationActionDoneModalOpen.value = true;
  };
  const fetchSatisfactions = async () => {
    dataLoading.value = true;
    await satisfactionStore.fetchSatisfaction(route.query);
    dataLoading.value = false;
  };
</script>

<style lang="scss" scoped></style>
