<template>
  <template v-if="!loading && detail">
    <formations-details-main v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Synthèse"
      v-bind:start_open="true">
      <template v-if="detail.participant">
        <refonte-table
          key="participant-1"
          table_index="participant-1"
          v-bind:columns="columnsParticipant"
          v-bind:data="[detail.participant]">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.commentairesElearning"
      class="mt-[12px]"
      v-bind:title_icon="IconInformation"
      title="Informations importantes"
      v-bind:start_open="true">
      <div class="ml-[20px] p-2">
        Particularités du module e-learning préalable aux sessions en présentiel
      </div>
      <div
        class="ml-[20px] p-2"
        v-html="detail.commentairesElearning" />
      <refonte-button
        variant="secondary"
        class="mb-[10px] ml-[20px] mt-[10px]"
        v-if="detail.cataloguePubliee"
        v-bind:icon_left="IconModify"
        v-bind:on_click="() => {}">
        Poser une question sur le module e-learning
      </refonte-button>
    </refonte-collapse>
    <refonte-collapse
      v-if="
        detail.titre &&
        detail?.moduleElearning?.cours &&
        detail?.moduleElearning?.cours?.length > 0
      "
      class="mt-[12px]"
      v-bind:title_icon="IconCatalogue"
      title="Chapitres e-learning"
      v-bind:start_open="true">
      <template
        v-if="
          detail.moduleElearning && detail?.moduleElearning.cours.length > 0
        ">
        <refonte-table
          key="supports-1"
          table_index="supports-1"
          v-bind:columns="columnsModuleElearning"
          v-bind:data="detail?.moduleElearning?.cours">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
      <refonte-button
        variant="success"
        class="ml-[20px]"
        v-bind:icon_left="IconOpenNewTab"
        v-bind:on_click="
          () => {
            /** TODO #692 redirection module **/
          }
        ">
        Accéder au module
      </refonte-button>
    </refonte-collapse>
    <refonte-collapse
      v-if="
        detail.titre &&
        detail?.support?.supports &&
        detail?.support?.supports?.length > 0
      "
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Supports pédagogiques"
      v-bind:start_open="true">
      <template v-if="detail.support && detail?.support.supports.length > 0">
        <refonte-table
          key="supports-1"
          table_index="supports-1"
          v-bind:columns="columnsSupports"
          v-bind:data="detail?.support?.supports">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
  <refonte-modal-satisfaction-participant
    v-if="selectedParticipant"
    v-bind:show="isParticipantSatisfactionModalOpen"
    v-bind:on_close="closeParticipantSatisfactionModal"
    v-bind:participant="selectedParticipant"
    v-bind:session_reference="detail.sessionReference"
    v-bind:client_id="detail.clientId" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, h, onMounted, ref, watch } from 'vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconCatalogue from '@/assets/img/refonte/icons/menu/icon-catalogue.svg?component';
  import IconInformation from '@/assets/img/refonte/icons/information/icon-information.svg?component';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import IconOpenNewTab from '@/assets/img/refonte/icons/action/icon-open-new-tab.svg?component';
  import IconEye from '@/assets/img/refonte/icons/display/icon-eye.svg?component';
  import { Participant } from '@/types/gestion/inscriptions-details-types';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import { useRoute } from 'vue-router';
  import RefonteModalSatisfactionParticipant from '@/components/refonte/modals/refonte-modal-satisfaction-participant.vue';
  import FormationsDetailsMain from '@/views/_espace/_refonte/participant/_formations-details/formations-details-main.vue';
  import { dataElearning } from '@/stores/data/formations-details-data';
  import { FormationsDetail } from '@/types/participant/formations-details-types';
  import RefonteTableParticipantFormationTestValidationAquis from '@/components/refonte/tables/refonte-table-participant-formation-test-validation-aquis.vue';
  import { Facture } from '@/types/gestion/factures-types';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import IconValidate20Green from '@/assets/img/refonte/icons/information/icon-validate-20-green.svg?component';

  const detail = ref<FormationsDetail>();
  const route = useRoute();
  const loading = ref<boolean>(false);
  //const id = computed(() => route.query.id);
  const isParticipantSatisfactionModalOpen = ref<boolean>(false);
  const selectedParticipant = ref<Participant>();

  watch(
    () => route.query.id,
    () => fetchFormationDetail()
  );

  const fetchFormationDetail = async () => {
    loading.value = true;

    /* if (id.value) {
    const response = await API.get(
      `client/v2/inscriptions/session/${id.value}/details${produitId.value ? `?produitId=${produitId.value}` : ''}`
    );

    if (response && response.data) {
      detail.value = mapInscriptionDetail(response.data);
    }
  }*/
    detail.value = mapFormationDetail(dataElearning);
    loading.value = false;
  };

  const mapFormationDetail = (detail) => {
    return {
      ...detail,
      support: {
        ...detail.support,
        supports: detail?.support?.supports.map((s) => mapSupport(s))
      }
    };
  };
  const mapSupport = (support) => {
    return {
      ...support,
      supportLabel: 'Télécharger'
    };
  };
  onMounted(async () => {
    await fetchFormationDetail();
  });

  function closeParticipantSatisfactionModal() {
    selectedParticipant.value = undefined;
    isParticipantSatisfactionModalOpen.value = false;
  }

  const getParticipantInscriptionStatus = (status: string) => {
    switch (status) {
      case 'En attente':
        return 'En attente';
      case 'Confirmé':
        return 'Confirmée';
      case 'Annulé':
        return 'Annulée';
      case 'Transférée':
        return 'Annulée';
      default:
        return '-';
    }
  };

  const columnsParticipant = computed(() => [
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 80,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    },
    {
      content_index: 'statut',
      label: 'Statut e-learning',
      width: 80,
      content_icon: (row: Participant) => IconValidate20Green
    },
    {
      content_index: 'validation',
      label: 'T. valid. acquis',
      width: 80,
      content_custom: (row: Participant) =>
        h(RefonteTableParticipantFormationTestValidationAquis, { row })
    },
    {
      content_index: 'satisfaction',
      label: 'Satisfaction',
      width: 80,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        }),
      content_icon: (row: Participant) =>
        row.satisfaction?.label === 'Réalisé' ? IconEye : undefined,
      on_click_disabled: (row: Participant) =>
        row.satisfaction && row.satisfaction.label !== 'Réalisé',
      on_click: (row: Participant) => {
        selectedParticipant.value = row;
        isParticipantSatisfactionModalOpen.value = true;
      }
    },
    {
      content_index: 'pif',
      label: 'Pif',
      width: 80,
      on_click: (row: Participant) => {},
      content_icon: (row: Participant) => IconDownload
    },
    {
      content_index: 'attestation',
      label: 'Attestation',
      width: 80,
      on_click: (row: Participant) => {},
      content_icon: () => IconDownload
    }
  ]);
  const columnsModuleElearning = [
    {
      content_index: 'titre',
      label: 'Titre',
      width: 150
    },
    {
      content_index: 'statutString',
      label: 'Statut',
      width: 150
    }
  ];
  const columnsSupports = [
    {
      content_index: 'titre',
      label: 'Titre',
      width: 200
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 150
    },
    {
      content_index: 'version',
      label: 'Version',
      width: 150
    },
    {
      content_index: 'dateMiseAJour',
      label: 'Mise à jour',
      width: 150
    },
    {
      content_index: 'dateDisponibilite',
      label: "Disponible jusqu'au",
      width: 150
    },
    {
      content_index: 'supportLabel',
      label: 'Support',
      min_width: 140,
      width: 170,
      on_click: (row: Facture) => {},
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
