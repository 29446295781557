<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    v-bind:title="title"
    class="!max-w-[704px]">
    <template v-slot:modalBody>
      <div
        class="flex flex-col gap-[8px] pb-[12px]"
        v-if="!loading">
        <div
          v-for="(section, sectionIndex) in props.sections"
          v-bind:key="`section-${sectionIndex}`"
          class="flex flex-col gap-[8px] pb-[8px]">
          <refonte-label
            v-if="section.title"
            class="gap-[8px]"
            v-bind:label="section.title"
            v-bind:label_icon="section.icon" />
          <div
            v-for="(file, fileIndex) in section.files"
            v-bind:key="`file-${fileIndex}`"
            class="flex flex-wrap gap-[8px]">
            <refonte-item-file
              v-bind:title="file.title"
              v-bind:subtitle="file.subtitle"
              v-bind:on_click_download="
                () => {
                  if (file && props.file_on_click_download) {
                    props.file_on_click_download(file);
                  }
                }
              "
              v-bind:on_click_upload_refresh="props.file_on_click_upload_url"
              v-bind:upload_url="
                props.build_upload_url_for_file
                  ? props.build_upload_url_for_file(file)
                  : ''
              "
              v-bind:id="file.id ? file.id.toString() : ''"
              v-bind:file_signed_label="props.file_signed_label"
              v-bind:file_to_sign_label="props.file_to_sign_label"
              v-bind:file_signed="file.signed"
              v-bind:no_signature_needed="props.file_no_signature_needed" />
          </div>
          <RefonteSeparator
            v-if="sectionIndex !== sections.length - 1"
            class="pt-[12px]" />
        </div>
      </div>
      <spinner
        sizes="w-20 h-20"
        thickness="border-8"
        v-else />
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import Spinner from '@/components/utils/spinner.vue';

  export interface ModalFile {
    formation?: string;
    title?: string;
    subtitle?: string;
    signed?: boolean;
    id?: number;
  }

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      title?: string;
      sections: {
        title?: string;
        icon?: Element;
        files: ModalFile[];
      };
      file_on_click_download?: Function;
      file_on_click_upload_url?: Function;
      build_upload_url_for_file?: Function;
      file_signed_label?: string;
      file_to_sign_label?: string;
      file_no_signature_needed?: boolean;
      class?: string;
      loading?: boolean;
    }>(),
    {
      title: 'Document',
      file_signed_label: 'Signée',
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
