<template>
  <div
    class="refonte-details-header"
    v-bind:class="props.class">
    <refonte-button
      class="details-back"
      variant="secondary"
      v-bind:icon_left="IconChevronLeft"
      v-on:click="back">
      Retour
    </refonte-button>
    <div class="details-header-title-section">
      <div class="details-header-title-section-left">
        <div
          v-if="detail.reglementStatutSuivi"
          class="details-header-title-section-left-tags">
          <refonte-tag v-bind:variant="buildTagStatut()">
            {{ buildTagLabel() }}
          </refonte-tag>
        </div>
        <h1>
          {{
            `Commande ${detail.reference} - ${number_utils.methods.formatCurrencyNoDigit(detail.montantHt)} HT`
          }}
        </h1>
      </div>
      <div class="details-header-title-section-right">
        <refonte-button
          v-on:click="downloadBonCommande"
          variant="secondary"
          v-bind:icon_left="IconDownload">
          Bon de commande
        </refonte-button>
      </div>
    </div>
    <div class="details-header-infos-section">
      <refonte-card class="details-header-infos-section-left">
        <refonte-details-header-item
          v-bind:icon_left="IconParticipants"
          v-bind:text="`Participants : ${detail.nbParticipants ?? 0}`" />
        <refonte-details-header-item
          v-if="detail.client"
          v-bind:icon_left="IconCommandes"
          v-bind:text="
            detail.createdBy && `Commandé par ${detail.createdBy}`
          " />
      </refonte-card>
      <refonte-card
        v-if="detail.vendeur || detail.payeur"
        class="details-header-infos-section-right">
        <refonte-details-header-item
          v-if="detail.vendeur"
          v-bind:icon_left="IconFactures"
          v-bind:text="`Vendu par ${detail.vendeur.nom}`" />
        <refonte-details-header-item
          v-if="detail.payeur"
          v-bind:icon_left="IconFactures"
          v-bind:text="`Payeur : ${detail.payeur.denomination}`" />
      </refonte-card>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconChevronLeft from '@/assets/img/refonte/icons/display/icon-chevron-left.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import IconCommandes from '@/assets/img/refonte/icons/menu/icon-orders.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteDetailsHeaderItem from '@/components/refonte/details/refonte-details-header-item.vue';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import { useRouter } from 'vue-router';
  import http_functions from '@/mixin/http_functions';
  import number_utils from '@/mixin/number_utils';

  const props = withDefaults(
    defineProps<{
      detail: any;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const buildTagStatut = () => {
    if (props.detail.montantRegle === props.detail.montantTtc) {
      return 'success';
    } else {
      return 'danger';
    }
  };

  const buildTagLabel = () => {
    if (props.detail.montantRegle === props.detail.montantTtc) {
      return 'Réglée';
    } else {
      return 'Non réglée';
    }
  };

  const router = useRouter();
  const back = () => {
    router.back();
  };

  const downloadBonCommande = () => {
    if (props.detail?.client?.id && props.detail?.id) {
      http_functions.methods.downloadBlobFile(
        `client/${props.detail.client.id}/commande/${props.detail.id}/bon/file`,
        `bon_commande${props.detail.reference ? `_${props.detail.reference}` : ''}.pdf`
      );
    }
  };
</script>

<style lang="scss" scoped>
  .refonte-details-header {
    @apply flex flex-col items-start self-stretch;
    gap: 12px;

    .details-back {
      margin-bottom: 12px;
    }

    .details-header-title-section {
      @apply flex flex-wrap items-center justify-end self-stretch;
      color: #000;
      gap: 16px;
      padding: 0 12px;

      .details-header-title-section-left {
        @apply flex flex-grow flex-col items-start justify-center self-stretch;
        gap: 2px;

        .details-header-title-section-left-tags {
          @apply flex flex-wrap items-center;
          gap: 2px;
        }
      }
    }

    .details-header-infos-section {
      @apply flex items-stretch self-stretch;
      gap: 12px;

      .details-header-infos-section-left,
      .details-header-infos-section-right {
        @apply flex flex-row flex-wrap items-start justify-items-start gap-0;
        flex: 1 0 0;
      }
    }
  }
</style>
