import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      d: "M10.113 10.078 13 13M7.124 3c2.277 0 4.124 1.87 4.124 4.175 0 2.306-1.847 4.175-4.124 4.175C4.846 11.35 3 9.48 3 7.175 3 4.869 4.846 3 7.124 3ZM7.15 5.2v4m-2-2h4"
    }, null, -1)
  ])))
}
export default { render: render }