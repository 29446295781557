<template>
  <refonte-page-title v-bind:description_in_card="true">
    Bienvenue dans le nouvel Espace Participant JINIUS.<br /><br />
    Cet espace a été conçu pour vous offrir un accès simple et rapide à toutes
    les informations concernant vos formations.
    <br />Vous y trouvez également les actions à réaliser pour compléter vos
    formations.
  </refonte-page-title>
  <refonte-collapse
    class="mt-[12px]"
    v-bind:title_icon="IconFormations"
    title="Mes formations"
    v-bind:start_open="true">
    <div class="btn-dashbord-container">
      <template v-if="!indicateurLoading">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => {}">
          <span
            >Vous avez <b>7</b>tests de positionnement à remplir pour valider
            votre inscription.</span
          >
          <br />
          <span class="!text-sm italic text-core-500"
            >Ne tardez pas à les finaliser afin de confirmer votre inscription.
          </span>
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => {}">
          <span
            >Il vous reste <b>5</b>tests de validation des acquis à effectuer.
          </span>
          <br />
          <span class="!text-sm italic text-core-500"
            >Ces étapes sont indispensables pour accéder à vos attestations.
          </span>
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => {}">
          <span
            >Vous devez signer <b>8</b>documents PIFs (Protocole Individuel de
            Formation).</span
          >
          <br />
          <span class="!text-sm italic text-core-500"
            >La signature des PIFs est indispensable pour valider votre
            inscription aux formations e-learning.
          </span>
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => {}">
          <span>Vous avez <b>5</b>modules e-learning à débuter.</span>
          <br />
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => {}">
          <span>Vous avez <b>1</b>module e-learning à terminer.</span>
          <br />
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/inscription/formations')">
            Retrouvez vos formations dans le menu Formations.
          </refonte-button>
        </div>
      </template>
      <spinner
        v-else
        sizes="w-20 h-20"
        thickness="border-8" />
    </div>
  </refonte-collapse>
</template>
<script setup lang="ts">
  import RefontePageTitle from '@/components/refonte/titles/refonte-page-title.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { router } from '@/router';
  import { ref } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';

  const indicateurLoading = ref<boolean>(false);
</script>

<style lang="scss" scoped>
  .btn-dashbord-container {
    @apply flex flex-col;
    margin-bottom: 8px;
    gap: 8px;
    padding: 0 10px;

    .btn-dashboard {
      @apply w-full justify-between text-left text-refonte-black;
      max-width: 100% !important;

      b {
        @apply mr-1 text-refonte_blue-600;
      }
    }
    :deep(.refonte-btn.btn-secondary span) {
      overflow: initial;
      text-overflow: initial;
      display: initial;
      -webkit-box-orient: initial;
      -webkit-line-clamp: initial;
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
    }
  }
</style>
