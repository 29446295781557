<template>
  <div
    class="refonte-select"
    v-bind:class="`${props.error ? 'input-error' : ''} ${props.disabled ? 'input-disabled' : ''} ${props.class}`">
    <vue-multiselect
      v-bind:model-value="selected"
      v-bind:class="props.select_class"
      v-bind:disabled="props.disabled"
      v-bind:multiple="false"
      v-on:update:model-value="updateValue"
      v-bind:options="props.options"
      placeholder="Rechercher..."
      v-bind:preserve-search="true"
      v-bind:allow-empty="false"
      v-bind:label="value_label"
      select-label=""
      selected-label=""
      deselect-label=""
      v-bind:close-on-select="true">
      <template v-slot:noResult><refonte-search-results-empty /></template>
      <template v-slot:noOptions><refonte-search-results-empty /></template>
    </vue-multiselect>
    <label v-bind:class="{ 'select-with-value': selected !== '' }">
      {{ props.placeholder }}<span v-if="props.required"> *</span>
    </label>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import VueMultiselect from 'vue-multiselect';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';

  const props = withDefaults(
    defineProps<{
      value_label?: string;
      value?: string;
      options: string[];
      placeholder: string;
      disabled?: boolean;
      required?: boolean;
      error?: boolean;
      class?: string;
      select_class?: string;
    }>(),
    {
      value: '',
      disabled: false,
      required: false,
      error: false,
      class: '',
      select_class: ''
    }
  );

  const selected = ref<string>(props.value);

  const emit = defineEmits(['input']);

  function updateValue(newValue: string) {
    selected.value = newValue;
    emit('input', newValue);
  }
</script>

<style lang="scss" scoped>
  @use 'sass:color';
  .refonte-select {
    @apply relative;

    :deep(.multiselect) {
      @apply relative border border-refonte_blue-100;
      border-radius: 4px;

      .multiselect__select {
        padding-left: 8px;
        padding-right: 8px;
        width: 26px;

        &:before {
          @apply border-t-refonte_blue-600;
          margin-top: 0;
        }
      }
      .multiselect__tags {
        @apply bg-refonte-white;
        padding: 17px 21px 4px 7px;
        border-radius: 4px;
        height: 42px;

        .multiselect__single {
          @apply text-refonte-black;
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
          padding-right: 7px;
        }
        input {
          @apply text-refonte-black;
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;

          &::placeholder {
            @apply text-refonte_blue-300;
          }
          &:focus-visible {
            outline: none;
          }
        }
        .multiselect__placeholder {
          @apply invisible;
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .multiselect__content-wrapper {
        @apply border-0;
        box-shadow: 0 3px 9px 0 #a9b2d1;
        margin-left: 0;
        margin-top: 4px;
        border-radius: 4px;
        padding: 8px;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: none;
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #505d8a;
          border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: color.adjust(#505d8a, $blackness: 10%);
          cursor: pointer;
        }

        .multiselect__content {
          @apply flex flex-col items-start self-stretch;

          .multiselect__element {
            .multiselect__option {
              @apply flex cursor-pointer items-center self-stretch whitespace-nowrap bg-transparent text-refonte_blue-600;
              gap: 2px;
              border-radius: 4px;
              padding: 8px;
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;
              min-height: 0;

              &.multiselect__option--highlight,
              &:hover {
                @apply bg-refonte-pale-violet-2 text-refonte_blue-600;
              }
              &.multiselect__option--selected {
                @apply bg-refonte-pale-violet-3 text-refonte_blue-600;
              }
            }
          }
        }
      }

      &.multiselect--above {
        .multiselect__content-wrapper {
          @apply border-0;
          box-shadow: 0 0 9px 0 #a9b2d1;
          margin-top: 0;
          margin-bottom: 4px;
        }
      }
      &:hover {
        @apply border-refonte_blue-600;
      }
      &.multiselect--active {
        z-index: unset;
        @apply border-refonte_blue-800;
      }
      &.multiselect--disabled {
        @apply border-refonte_blue-200;

        .multiselect__select {
          &:before {
            @apply border-t-refonte_blue-200 #{!important};
          }
        }
        .multiselect__tags {
          .multiselect__single {
            @apply text-refonte_blue-200;
          }
          input {
            @apply text-refonte_blue-200 #{!important};

            &::placeholder {
              @apply text-refonte_blue-200 #{!important};
            }
          }
        }
      }
    }
    label {
      @apply absolute text-refonte_blue-300;
      left: 8px;
      top: 14px;
      transform: translateY(0);
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
    label.select-with-value,
    .multiselect--active + label {
      transform: translateY(-9px);
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
    }

    &.input-error {
      :deep(.multiselect) {
        @apply border-jinius-red #{!important};

        .multiselect__select {
          &:before {
            @apply border-t-jinius-red #{!important};
          }
        }
        .multiselect__tags {
          .multiselect__single {
            @apply text-jinius-red #{!important};
          }
          input {
            @apply text-jinius-red #{!important};

            &::placeholder {
              @apply text-jinius-red #{!important};
            }
          }
        }
      }
      label {
        @apply text-jinius-red #{!important};
      }
    }

    &.input-disabled {
      cursor: not-allowed !important;
    }
  }
</style>
