<template>
  <div
    class="refonte-item-card-with-button"
    v-bind:class="props.class">
    <div class="item-content-title">
      <div class="item-content-title-text">
        {{ props.title }}
      </div>
    </div>
    <refonte-button
      variant="secondary"
      v-on:click="props.on_click"
      >Voir</refonte-button
    >
  </div>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  const props = withDefaults(
    defineProps<{
      title: string;
      on_click: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-item-card-with-button {
    @apply flex flex-grow items-center bg-refonte-pale-violet-2;
    gap: 4px;
    border-radius: 8px;
    padding: 6px 6px 6px 12px;
    max-width: 640px;
    width: 100%;
    min-height: 48px;

    .item-content-title {
      @apply flex flex-grow items-start;
      gap: 2px;

      .item-content-title-text {
        @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
</style>
