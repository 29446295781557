<template>
  <template v-if="!loading && metadataLoaded">
    <refonte-card>
      <refonte-filters-tabs>
        <template v-slot:default>
          <inscriptions-buttons-filters />
        </template>
      </refonte-filters-tabs>
      <inscriptions-filters
        v-bind:metadatas="metadatas"
        v-if="metadataLoaded" />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <inscriptions-counts v-bind:inscription_count="inscriptionCount" />
      <inscriptions-content
        v-if="!dataLoading"
        v-bind:fetch="fetchData" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import InscriptionsContent from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-content.vue';
  import InscriptionsCounts from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-counts.vue';
  import {
    INSCRIPTIONS_FILTERS_CONFIGURATION,
    useInscriptionFilterStore,
    useInscriptionStore
  } from '@/stores/inscriptions';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import InscriptionsFilters from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import filters_utils from '@/mixin/utils/filters_utils';
  import {
    Filters,
    InscriptionCount,
    InscriptionMetadata
  } from '@/types/gestion/inscriptions-filters-types';
  import { API } from '@/http-common';
  import InscriptionsButtonsFilters from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-buttons-filters.vue';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const inscriptionFilterStore = useInscriptionFilterStore();

  const { filters } = storeToRefs(inscriptionFilterStore);

  const dataLoading = ref<boolean>(false);
  const inscriptionStore = useInscriptionStore();

  const fetchData = async () => {
    dataLoading.value = true;
    await fetchCount(route.query);
    await inscriptionStore.fetchInscriptions(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );
  const initFilters = async () => {
    inscriptionFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(async () => {
    loading.value = true;
    await initFilters();
    if (!activeClientIds.value) {
      await fetchDefaultData();
    }
    await fetchMetadata();
    loading.value = false;
  });

  const router = useRouter();

  watch(filters, async (newValue, oldValue) => {
    let query = getQuery(filters.value);

    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    if (
      filters_utils.methods.isDeepEqual(query, route.query) &&
      query.length > 0
    ) {
      await fetchData();
    } else {
      await router.push({ query });
    }
  });

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, [
      INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);

  const inscriptionCount = ref<InscriptionCount>();

  const fetchCount = async (query: Filters) => {
    const response = await API.get('client/v2/inscriptions/count', {
      params: query
    });

    if (response && response.data) {
      inscriptionCount.value = response.data;
    }
  };

  watch(activeClientIds, async () => {
    fetchMetadata();
  });

  const metadataLoaded = ref<boolean>(false);
  const metadatas = ref<InscriptionMetadata>({
    formations: [],
    participants: [],
    organisateurs: []
  });
  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    const response = await API.get(
      `client/v2/inscriptions/metadata${activeClientIds.value.length ? '?clientIds=' + activeClientIds.value : ''}`
    );

    if (response && response.data) {
      metadatas.value = response.data;
    }
    metadataLoaded.value = true;
  };
</script>

<style lang="scss" scoped></style>
