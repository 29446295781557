<template>
  <template v-if="!loading">
    <refonte-card>
      <sessions-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <sessions-count v-bind:session_count="sessionCount" />
      <refonte-separator class="px-[12px]" />
      <sessions-content
        v-if="!dataLoading"
        v-bind:fetch="fetchData" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SessionsContent from '@/views/_espace/_refonte/gestion/_sessions/sessions-content.vue';
  import {
    SessionCount,
    SESSIONS_FILTERS_CONFIGURATION,
    useSessionFilterStore,
    useSessionStore
  } from '@/stores/sessions';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import SessionsCount from '@/views/_espace/_refonte/gestion/_sessions/sessions-count.vue';
  import SessionsFilters from '@/views/_espace/_refonte/gestion/_sessions/sessions-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import { API } from '@/http-common';
  import filters_utils from '@/mixin/utils/filters_utils';
  import { Filters } from '@/types/gestion/inscriptions-filters-types';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const sessionFilterStore = useSessionFilterStore();

  const { filters } = storeToRefs(sessionFilterStore);

  const dataLoading = ref<boolean>(false);
  const sessionStore = useSessionStore();

  const fetchData = async () => {
    dataLoading.value = true;
    await fetchCount(route.query);
    await sessionStore.fetchSessions(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = async () => {
    sessionFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(async () => {
    await initFilters();
    await fetchDefaultData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = getQuery(filters.value);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    if (
      filters_utils.methods.isDeepEqual(query, route.query) &&
      query.length > 0
    ) {
      await fetchData();
    } else {
      await router.push({ query });
    }
  });

  const sessionCount = ref<SessionCount>();

  const fetchCount = async (query: Filters) => {
    const response = await API.get('client/v2/sessions/count', {
      params: query
    });

    if (response && response.data) {
      sessionCount.value = response.data;
    }
  };

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, [
      SESSIONS_FILTERS_CONFIGURATION.date.name,
      SESSIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      SESSIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);
</script>

<style lang="scss" scoped></style>
