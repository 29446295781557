<template>
  <template v-if="!loading && detail">
    <sessions-details-content
      v-bind:detail="detail"
      v-bind:fetch_formation="fetchFormation" />
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>

<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import { API } from '@/http-common';
  import { useRoute } from 'vue-router';
  import {
    buildConventionSectionList,
    buildDate,
    buildPifSectionList
  } from '@/stores/utils';
  import SessionsDetailsContent from '@/views/_espace/_refonte/gestion/_sessions-details/sessions-details-content.vue';

  const detail = ref();
  const loading = ref<boolean>(false);

  const route = useRoute();
  const id = computed(() => route.query.id);
  const clientId = computed(() => route.query.clientId);
  const cursusId = computed(() => route.query.cursusId);
  watch(
    () => route.query.id,
    () => fetchFormation()
  );

  watch(
    () => route.query.produitId,
    () => fetchFormation()
  );
  const fetchFormation = async () => {
    loading.value = true;
    if (clientId.value && id.value) {
      const response = await API.get(
        `client/v2/sessions/session/${cursusId.value}/details/session/${id.value}?clientIds=${clientId.value}`
      );
      if (response && response.data) {
        detail.value = mapDetail(response.data);
      }
    }

    loading.value = false;
  };

  const mapDetail = (detail) => {
    return {
      ...detail,
      dateDebut: detail.dateDebutSession,
      dateFin: detail.dateFinSession,
      titre: detail.titreProduit,
      sousTitre: detail.sousTitreProduit,
      commandeReferences: detail.referencesCommandes,
      sessionReference: detail.referenceSession,
      type: detail.typeProduit,
      participants: detail.participants.map((p) => mapParticipant(p)),
      conventions: buildConventionSectionList(
        detail.conventions,
        detail.titreProduit
      ),
      pifs: buildPifSectionList(detail.pifs, detail.titreProduit)
    };
  };
  const mapParticipant = (p) => {
    return {
      ...p,
      elearningLabel: p.elearning?.label ?? undefined,
      nomPrenom: p.nomPrenom ?? 'Réservation',
      datesAcces: buildDate(p.dateDebutAcces, p.dateFinAcces)
    };
  };

  onMounted(async () => {
    await fetchFormation();
  });
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
