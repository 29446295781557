<template>
  <div class="table-item-paiement-instruction">
    <div class="table-item-row">
      <div class="table-item-content-icon">
        <IconValidate20Green v-if="props.montant_regle === props.montant_ttc" />
        <IconFailed20Red v-else />
      </div>
      <span class="table-item-text">{{ props.montant_regle }}</span>
    </div>
    <div
      v-if="props.montant_regle !== props.montant_ttc && props.reglement.label"
      class="table-item-button"
      v-bind:onclick="openModal">
      <div class="table-item-content-icon">
        <IconZoomIn />
      </div>
      <span class="table-item-label">Voir les instructions</span>
    </div>
  </div>
  <commandes-modal-paiement-instruction
    v-bind:show="isInstructionPaiementModalOpen"
    v-bind:on_close="closeModal"
    v-bind:reglement="props.reglement" />
</template>

<script setup lang="ts">
  import IconValidate20Green from '@/assets/img/refonte/icons/information/icon-validate-20-green.svg?component';
  import IconFailed20Red from '@/assets/img/refonte/icons/information/icon-failed-20-red.svg?component';
  import IconZoomIn from '@/assets/img/refonte/icons/action/icon-zoom-in.svg?component';
  import { ref } from 'vue';
  import CommandesModalPaiementInstruction from '@/views/_espace/_refonte/gestion/_commandes/commandes-modal-paiement-instruction.vue';
  import { Reglement } from '@/types/gestion/commandes-types';

  const props = withDefaults(
    defineProps<{
      montant_regle: number;
      montant_ttc: number;
      reglement: Reglement;
    }>(),
    {}
  );

  const isInstructionPaiementModalOpen = ref<boolean>(false);

  const openModal = () => {
    isInstructionPaiementModalOpen.value = true;
  };

  const closeModal = () => {
    isInstructionPaiementModalOpen.value = false;
  };
</script>

<style lang="scss" scoped>
  .table-item-paiement-instruction {
    @apply flex flex-col;
    gap: 6px;

    .table-item-row {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
    }

    .table-item-content-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 20px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte_blue-200;
        width: 20px;
        height: 20px;
      }
    }

    .table-item-button {
      @apply inline-flex items-center justify-center;
      gap: 2px;
      padding: 7px 12px 7px 8px;
      border-radius: 4px;
      max-width: 100%;
      background-color: #fff3f3;

      .table-item-label {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
      }

      &:hover {
        background-color: #ffefee;
      }
    }
  }
</style>
