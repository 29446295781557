<template>
  <div
    class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
    <refonte-table-number
      v-if="
        props.inscription_count?.nbInscriptions ||
        props.inscription_count?.nbInscriptions == 0
      "
      v-bind:number="props.inscription_count?.nbInscriptions"
      v-bind:label="`${props.inscription_count?.nbInscriptions > 1 ? 'Inscriptions' : 'Inscription'}${
        filters.statutIds &&
        filters.statutIds.join(', ') ===
          INSCRIPTIONS_ANNULEES_STATUTS_IDS.join(', ')
          ? inscriptions.length > 1
            ? ' annulées/transférées'
            : ' annulée/transférée'
          : ''
      }`" />
    <IconSeparatorVertical v-if="showOptionalFilters" />
    <refonte-table-number
      v-if="
        showOptionalFilters &&
        (props.inscription_count?.nbConventionsASigner ||
          props.inscription_count?.nbConventionsASigner == 0)
      "
      v-bind:number="props.inscription_count?.nbConventionsASigner"
      v-bind:label="`${
        props.inscription_count?.nbConventionsASigner > 1
          ? 'Conventions'
          : 'Convention'
      } à signer`"
      v-bind:on_click="
        () => {
          inscriptionFilterStore.setFilter(
            INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
            'CONVENTIONS_A_SIGNER'
          );
        }
      " />
    <IconSeparatorVertical v-if="showOptionalFilters" />
    <refonte-table-number
      v-if="
        showOptionalFilters &&
        (props.inscription_count?.nbPifsAsigner ||
          props.inscription_count?.nbPifsAsigner == 0)
      "
      v-bind:number="props.inscription_count?.nbPifsAsigner"
      label="PIF à signer"
      v-bind:on_click="
        () => {
          inscriptionFilterStore.setFilter(
            INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
            'PIF_A_SIGNER'
          );
        }
      " />
    <IconSeparatorVertical v-if="showOptionalFilters" />
    <refonte-table-number
      v-if="
        showOptionalFilters &&
        (props.inscription_count?.nbTestPositionnementNonRealise ||
          props.inscription_count?.nbTestPositionnementNonRealise == 0)
      "
      v-bind:number="props.inscription_count?.nbTestPositionnementNonRealise"
      v-bind:label="`${
        props.inscription_count?.nbTestPositionnementNonRealise > 1
          ? 'Tests'
          : 'Test'
      } de positionnement non ${
        props.inscription_count?.nbTestPositionnementNonRealise > 1
          ? 'réalisés'
          : 'réalisé'
      }`"
      v-bind:on_click="
        () => {
          inscriptionFilterStore.setFilter(
            INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
            'T_POS_NON_REALISE'
          );
        }
      " />
    <div class="flex flex-grow items-center justify-end">
      <refonte-button
        v-bind:icon_left="IconExport"
        v-on:click="exportCsv">
        Exporter
      </refonte-button>
    </div>
  </div>
  <refonte-separator class="px-[12px]" />
</template>

<script setup lang="ts">
  import IconExport from '@/assets/img/refonte/icons/action/icon-export.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import {
    INSCRIPTIONS_ANNULEES_STATUTS_IDS,
    INSCRIPTIONS_EN_ATTENTE_STATUTS_IDS,
    INSCRIPTIONS_FILTERS_CONFIGURATION,
    INSCRIPTIONS_STATUTS_IDS,
    useInscriptionFilterStore,
    useInscriptionStore
  } from '@/stores/inscriptions';
  import { storeToRefs } from 'pinia';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import { InscriptionCount } from '@/types/gestion/inscriptions-filters-types';
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';

  const props = withDefaults(
    defineProps<{
      inscription_count?: InscriptionCount;
      count_loaded?: boolean;
    }>(),
    {
      count_loaded: false
    }
  );

  const inscriptionFilterStore = useInscriptionFilterStore();

  const { filters } = storeToRefs(inscriptionFilterStore);
  const inscriptionStore = useInscriptionStore();
  const { inscriptions } = storeToRefs(inscriptionStore);
  const showOptionalFilters = computed(() => {
    return (
      filters.value.statutIds &&
      (filters.value.statutIds.join(', ') ===
        INSCRIPTIONS_STATUTS_IDS.join(', ') ||
        filters.value.statutIds.join(', ') ===
          INSCRIPTIONS_EN_ATTENTE_STATUTS_IDS.join(', '))
    );
  });

  const route = useRoute();
  const exportCsv = async () => {
    await inscriptionStore.exportCsv(route.query);
  };
</script>

<style lang="scss" scoped></style>
