<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    title="Pour votre réglement">
    <template v-slot:modalBody>
      <div class="py-2">
        <span class="mb-2 font-bold text-primary-800">
          Rappel du mode de règlement choisi&nbsp;:
        </span>
        <span
          class="mx-1.5 rounded bg-accent-100 px-2 pb-0.5 pt-1 text-sm font-bold shadow">
          {{ props.reglement.label }}
        </span>
      </div>
      <div
        class="mb-2 py-2"
        v-if="props.reglement.instructions"
        v-html="props.reglement.instructions"></div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button v-bind:on_click="props.on_close"> Fermer </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import { Reglement } from '@/types/gestion/commandes-types';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      class?: string;
      reglement: Reglement;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
