<template>
  <button
    class="refonte-btn"
    v-bind:class="`btn-${props.variant} btn-${props.size} ${!!slots['tag'] ? 'btn-with-tag' : ''} ${props.icon_left || !!slots['iconLeft'] ? 'btn-left-icon' : ''} ${props.icon_right || !!slots['iconRight'] ? 'btn-right-icon' : ''} ${props.class}`"
    v-bind:type="props.type"
    v-bind:disabled="props.disabled"
    v-on:click="props.on_click">
    <component
      v-if="props.icon_left"
      v-bind:is="props.icon_left" />
    <template v-if="!!slots['iconLeft']">
      <slot name="iconLeft"></slot>
    </template>
    <template v-if="!!slots['tag']">
      <slot name="tag"></slot>
    </template>
    <span v-if="!!slots['default']">
      <slot></slot>
    </span>
    <component
      v-if="props.icon_right"
      v-bind:is="props.icon_right" />
    <template v-if="!!slots['iconRight']">
      <slot name="iconLeft"></slot>
    </template>
  </button>
</template>

<script setup lang="ts">
  import { FunctionalComponent, useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      type?: 'button' | 'submit';
      variant?: string;
      disabled?: boolean;
      on_click?: Function;
      size?: 'sm' | 'md';
      icon_left?: Element | FunctionalComponent;
      icon_right?: Element;
      class?: string;
    }>(),
    {
      type: 'button',
      variant: 'primary',
      disabled: false,
      size: 'md',
      class: ''
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-btn {
    @apply inline-flex items-center;
    gap: 5px;
    border-radius: 4px;

    :deep(svg) {
      min-width: 16px;
      width: 16px;
      height: 16px;
    }
    span {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
    &.btn-with-tag {
      max-width: 100% !important;
    }

    &.btn-primary,
    &.btn-success,
    &.btn-danger {
      gap: 5px;

      &.btn-sm {
        padding: 7px 16px;
      }
      &.btn-md {
        padding: 10px 16px;
      }
      &.btn-left-icon,
      &.btn-right-icon {
        padding: 10px 9px;
      }
      &.btn-left-icon {
        padding-left: 7px;
      }
      &.btn-right-icon {
        padding-right: 7px;
      }
    }

    &.btn-primary {
      @apply bg-refonte_blue-400 text-refonte-white;

      :deep(svg) {
        @apply text-refonte-white;
      }

      &:hover {
        @apply bg-refonte_blue-600;
      }
      &:active,
      &:focus {
        @apply bg-refonte_blue-800;
      }
      &:disabled {
        @apply cursor-not-allowed bg-refonte_blue-100;
      }
    }
    &.btn-success {
      @apply bg-jinius-green text-refonte-white;

      :deep(svg) {
        @apply text-refonte-white;
      }

      &:hover,
      &:active,
      &:focus {
        @apply opacity-60;
      }
      &:disabled {
        @apply cursor-not-allowed opacity-20;
      }
    }
    &.btn-danger {
      @apply bg-jinius-red text-refonte-white;

      :deep(svg) {
        @apply text-refonte-white;
      }

      &:hover,
      &:active,
      &:focus {
        @apply opacity-60;
      }
      &:disabled {
        @apply cursor-not-allowed opacity-20;
      }
    }
    &.btn-secondary {
      @apply border border-refonte_blue-200 bg-refonte-white text-refonte_blue-800;
      padding: 9px 11px;
      border-radius: 8px;
      max-width: 400px;

      :deep(svg) {
        @apply text-refonte_blue-600;
      }
      span {
        @apply overflow-hidden text-ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 16px;
      }

      &:hover,
      &:active,
      &:focus {
        @apply bg-refonte-pale-violet-3;
      }
      &:disabled {
        @apply cursor-not-allowed bg-refonte_blue-100 text-refonte-white;

        :deep(svg) {
          @apply text-refonte-white;
        }
      }
      &:disabled {
        @apply cursor-not-allowed bg-refonte-white text-refonte_blue-100;

        :deep(svg) {
          @apply text-refonte_blue-100;
        }
      }
    }
    &.btn-text {
      @apply text-refonte_blue-600;
      padding: 8px;

      :deep(svg) {
        @apply text-refonte_blue-600;
      }

      &:hover,
      &:active,
      &:focus {
        @apply text-refonte_blue-800 underline;
      }
      &:disabled {
        @apply cursor-not-allowed text-refonte_blue-200 no-underline;
      }
    }
    &.btn-filter {
      @apply text-refonte_blue-600;
      padding: 9px 10px;

      :deep(svg) {
        @apply text-refonte_blue-600;
      }
      span {
        font-size: 14px;
      }

      &:hover,
      &:active,
      &:focus {
        @apply bg-refonte-pale-violet-2;
      }
      &:disabled {
        @apply cursor-not-allowed bg-refonte-white text-refonte_blue-200;
      }
    }
    &.btn-select {
      @apply border border-refonte_blue-100 bg-refonte-white text-refonte_blue-600;
      padding: 9px 11px;
      border-radius: 8px;
      max-width: 400px;

      :deep(svg) {
        @apply text-refonte_blue-600;
      }
      span {
        @apply overflow-hidden text-ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 16px;
      }

      &:hover {
        @apply border-refonte_blue-400 bg-refonte_blue-400 text-refonte-white;

        :deep(svg) {
          @apply text-refonte-white;
        }
      }
      &:active,
      &:focus {
        @apply border-refonte_blue-800 bg-refonte_blue-800 text-refonte-white;

        :deep(svg) {
          @apply text-refonte-white;
        }
      }
      &:disabled {
        @apply cursor-not-allowed border-refonte_blue-100 bg-refonte-white text-refonte_blue-100;

        :deep(svg) {
          @apply text-refonte_blue-100;
        }
      }
    }
    &.btn-sort {
      @apply bg-refonte-pale-violet-2 text-refonte_blue-600;
      padding: 10px;
      gap: 6px;
      max-width: 216px;

      :deep(svg) {
        @apply text-refonte_blue-600;
      }
      span {
        @apply overflow-hidden text-ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 16px;
      }

      &:hover,
      &:active,
      &:focus {
        @apply bg-refonte-pale-violet-3;
      }
      &:disabled {
        @apply cursor-not-allowed bg-refonte-pale-violet-2 text-refonte_blue-100;

        :deep(svg) {
          @apply text-refonte_blue-100;
        }
      }
    }
    &.btn-tag {
      @apply shrink-0 justify-center bg-refonte_blue-800 text-refonte-white;
      padding: 4px 5px;
      border-radius: 4px;
      gap: 6px;
      width: 24px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte-white;
        min-width: 18px;
        width: 18px;
        height: 18px;
      }

      &:hover,
      &:active,
      &:focus {
        @apply bg-refonte_blue-900;
      }
      &:disabled {
        @apply cursor-not-allowed bg-refonte_blue-100;
      }
    }
  }
</style>
