import { Contrat } from '@/types/gestion/contrats-types';
import { Document } from '@/types/gestion/inscriptions-types';
import http_functions from './http_functions.js';

export const downloadContrat = (
    clientId: number | undefined,
    contrat: Contrat | undefined
) => {
    if (contrat?.valide && contrat?.id) {
        http_functions.methods.downloadFile(
            `client/${clientId}/contrats/${contrat.id}/file/signe`,
            'formateur_contrat.pdf'
        );
    } else if (contrat?.disponible && contrat?.id) {
        http_functions.methods.downloadFile(
            `client/${clientId}/contrats/${contrat.id}/file`,
            'formateur_contrat.pdf'
        );
    }
};

export const downloadBonCommande = (
    clientId: number | undefined,
    commandeId: number | undefined,
    reference: string | undefined
) => {
    http_functions.methods.downloadBlobFile(
        `client/${clientId}/commande/${commandeId}/bon/file`,
        `bon_commande${reference ? `_${reference}` : ''}.pdf`
    );
};
export const downloadCertificat = (
    clientId: number | undefined,
    certificatId: number | undefined | string
) => {
    http_functions.methods.downloadFile(
        `client/${clientId}/certificat/${certificatId}/file`,
        'certificat.pdf'
    );
};

export const downloadCertificatZip = (sessionId: number) => {
    http_functions.methods.downloadFile(
        `client/v2/sessions/session/${sessionId}/certificats/zip`,
        'certificat.zip'
    );
};

export const downloadCertificatZipByIds = (ids: string[]) => {
    http_functions.methods.downloadFile(
        `client/v2/certificats/zip?ids=${ids.join(',')}`,
        'certificat.zip'
    );
};

export const downloadFacture = (
    clientId: number | undefined,
    factureId: number | undefined
) => {
    http_functions.methods.downloadFile(
        `client/v2/${clientId}/facture/${factureId}/file`,
        'facture.pdf'
    );
};
export const downloadPif = (
    clientId: number | undefined,
    pif: Document | undefined
) => {
    if (clientId) {
        if (pif?.validee) {
            http_functions.methods.downloadFile(
                `client/${clientId}/pif/${pif.id}/file/validee`,
                'pif.pdf'
            );
        } else if (pif?.signee) {
            http_functions.methods.downloadFile(
                `client/${clientId}/pif/${pif.id}/file/signee`,
                'pif.pdf'
            );
        } else if (pif?.id) {
            http_functions.methods.downloadFile(
                `client/${clientId}/pif/${pif.id}/file`,
                'pif.pdf'
            );
        }
    }
};

export const downloadConvention = (
    clientId: number | undefined,
    convention: Document | undefined
) => {
    if (convention && clientId) {
        if (convention?.validee) {
            http_functions.methods.downloadFile(
                `client/${clientId}/convention/${convention.id}/file/validee`,
                'convention.pdf'
            );
        } else if (convention?.signee) {
            http_functions.methods.downloadFile(
                `client/${clientId}/convention/${convention.id}/file/signee`,
                'convention.pdf'
            );
        } else if (convention?.id) {
            http_functions.methods.downloadFile(
                `client/${clientId}/convention/${convention.id}/file`,
                'convention.pdf'
            );
        }
    }
};

export const getConventionUploadUrl = (
    clientId: number,
    convention: Document | undefined
) => {
    return `client/${clientId}/convention/${convention?.id}/file`;
};

export const getPifUploadUrl = (
    clientId: number,
    pif: Document | undefined
) => {
    return `client/${clientId}/pif/${pif?.id}/file`;
};
