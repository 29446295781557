<template>
  <div
    class="table-item-documents"
    title=" "
    v-on:click="
      (e) => {
        e.stopPropagation();
      }
    ">
    <div v-if="props.row.sessionStatutLabel !== 'Annulé'">
      <div
        v-if="props.row.pifStatut"
        class="table-item-document"
        v-on:click="async () => {}">
        <div class="table-item-document-icon">
          <component
            v-bind:is="
              getDocumentIcon(
                props.row.pif?.disponible,
                props.row.pif?.signee,
                props.row.pif?.validee
              )
            " />
        </div>
        <span class="table-item-document-text">
          PIF(s)&nbsp;: {{ props.row.pifStatut }}
        </span>
      </div>
      <div
        v-if="props.row.attestationId"
        class="table-item-document"
        v-on:click="async () => {}">
        <div class="table-item-document-icon">
          <component
            v-bind:is="props.row.attestationId ? IconDownload : undefined" />
        </div>
        <span class="table-item-document-text"> Attestation </span>
      </div>
      <div
        v-if="!props.row.attestationId && !props.row.pifStatut"
        class="table-item-document">
        <span class="table-item-document-text !text-refonte_blue-200"> - </span>
      </div>
    </div>
    <div
      v-else
      class="table-item-document">
      <span class="table-item-document-text !text-refonte_blue-200"> - </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import { Formation } from '@/types/participant/formations-types';

  const props = withDefaults(
    defineProps<{
      row: Formation;
      refresh_rows: Function;
    }>(),
    {}
  );

  const getDocumentIcon = (
    disponible?: boolean,
    signee?: boolean,
    validee?: boolean
  ) => {
    if (disponible && !signee && !validee) {
      return IconUpload;
    }
    if (signee || validee) {
      return IconDownload;
    }
  };
</script>

<style lang="scss" scoped>
  .table-item-documents {
    @apply flex flex-col;
    gap: 6px;

    .table-item-document {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-document-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }
      }
      .table-item-document-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
      &:hover {
        .table-item-document-text {
          @apply underline;
        }
      }

      &.table-item-document-to-sign {
        .table-item-document-icon {
          :deep(svg) {
            @apply text-refonte-secondary;
          }
        }
        .table-item-document-text {
          @apply text-refonte-secondary;
        }
      }
    }
  }
</style>
