<template>
  <template v-if="!loading">
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="etablissements.length"
          v-bind:label="
            etablissements.length > 1 ? 'Etablissements' : 'Etablissement'
          " />
      </div>
      <refonte-separator class="px-[12px]" />
      <etablissements-content
        v-if="!dataLoading"
        v-bind:disable_etablissement="disableEtablissement"
        v-bind:enable_etablissement="enableEtablissement"
        v-bind:update_etablissement="updateEtablissement" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
  <refonte-modal-confirmation-action
    v-bind:show="isConfirmationActionDoneModalOpen"
    v-bind:on_close="closeConfirmationActionDoneModal"
    v-bind:title="confirmationActionDoneModalTitle"
    v-bind:content="confirmationActionDoneModalDescription" />
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import EtablissementsContent from '@/views/_espace/_refonte/gestion/_etablissements/etablissements-content.vue';
  import {
    useEtablissementFilterStore,
    useEtablissementStore
  } from '@/stores/etablissements';
  import { storeToRefs } from 'pinia';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { Etablissement } from '@/types/gestion/etablissements-types';
  import { API } from '@/http-common';
  import RefonteModalConfirmationAction from '@/components/refonte/modals/refonte-modal-confirmation-action.vue';
  import filters_utils from '@/mixin/utils/filters_utils';
  import { Filters } from '@/types/gestion/inscriptions-filters-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeAdminClientIds } = storeToRefs(entrepriseStore);
  const etablissementFilterStore = useEtablissementFilterStore();

  const { filters } = storeToRefs(etablissementFilterStore);

  const dataLoading = ref<boolean>(false);
  const etablissementStore = useEtablissementStore();
  const { etablissements } = storeToRefs(etablissementStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await etablissementStore.fetchEtablissements(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = async () => {
    etablissementFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(async () => {
    await initFilters();
    if (!activeAdminClientIds.value) {
      await fetchDefaultData();
    }
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = getQuery(filters.value);
    if (activeAdminClientIds.value && activeAdminClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeAdminClientIds.value
      };
    }
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    } else {
      await router.push({ query });
    }
  });

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, []);

  const isConfirmationActionDoneModalOpen = ref<boolean>(false);
  const confirmationActionDoneModalTitle = ref<string>();
  const confirmationActionDoneModalDescription = ref<string>();

  function closeConfirmationActionDoneModal() {
    isConfirmationActionDoneModalOpen.value = false;
    confirmationActionDoneModalDescription.value = undefined;
  }
  const disableEtablissement = async (
    etablissement: Etablissement,
    motif: string
  ) => {
    const { denomination } = etablissement;
    const response = await API.post(
      `client/v2/etablissements/${etablissement.id}/desactiver`,
      { desactivationMotif: motif }
    );
    if (response.status === 200) {
      isConfirmationActionDoneModalOpen.value = true;
      confirmationActionDoneModalTitle.value = 'Établissement désactivé';
      confirmationActionDoneModalDescription.value = `L'établissement ${denomination} a bien été désactivé.`;
      await fetchData();
    }
  };
  const enableEtablissement = async (etablissement: Etablissement) => {
    const { denomination } = etablissement;
    const response = await API.post(
      `client/v2/etablissements/${etablissement.id}/reactiver`
    );
    if (response.status === 200) {
      isConfirmationActionDoneModalOpen.value = true;
      confirmationActionDoneModalTitle.value = 'Établissement réactivé';
      confirmationActionDoneModalDescription.value = `L'établissement ${denomination} a bien été réactivé.`;
      await fetchData();
    }
  };
  const updateEtablissement = async (form: Etablissement) => {
    const { denomination, id } = form;
    const body = {
      ...form,
      siret: form.siret ? form.siret.replace(/\s/g, '') : null
    };

    const response = await API.put(`/client/${id}/informations`, body);

    if (response.status === 200) {
      isConfirmationActionDoneModalOpen.value = true;
      confirmationActionDoneModalTitle.value = 'Informations modifiées';
      confirmationActionDoneModalDescription.value = `L'établissement ${denomination} a bien été modifié.`;
    }
  };
</script>

<style lang="scss" scoped></style>
