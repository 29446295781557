<template>
  <div class="inscription-container">
    <template v-if="!collapse">
      <inscription-card
        v-bind:inscription="{
          ...inscription,
          type: getTypeList(
            inscription.classeVirtuelle,
            inscription.type,
            inscription.ficheType
          )
        }"
        v-on:open-positionnement="openPositionnement"
        v-bind:espace="espace" />
    </template>
    <template v-else>
      <inscription-card
        v-bind:inscription="{
          ...inscription,
          type: getTypeList(inscription.classeVirtuelle, inscription.type)
        }"
        v-bind:parcours="true"
        v-bind:collapsed="collapsed"
        v-on:open-positionnement="openPositionnement"
        v-bind:toggle_collapse="toggleCollapse"
        v-bind:espace="espace" />

      <div
        class="-mt-3 ml-2 border-l border-accent-300 pl-4 pt-4 transition lg:ml-6 lg:pl-6"
        v-bind:class="`${collapsed ? 'collapsed' : ''}`">
        <transition-group name="slide-up">
          <template v-for="produit in produits">
            <inscription-card
              v-if="!collapsed"
              v-on:open-positionnement="
                (value) => openPositionnement({ ...value, produit: true })
              "
              v-bind:key="`produit-${produit.id}`"
              v-bind:inscription="{
                ...produit,
                type: getTypeList(produit.classeVirtuelle, produit.type),
                organisateur: inscription.organisateur,
                participants: inscription.isCursus
                  ? produit.participants
                  : inscription.participants,
                participantStatut: inscription.isCursus
                  ? produit.participantStatut
                  : inscription.participantStatut,
                ...(produit.type &&
                  produit.type.id === 1 && {
                    lieu: produit.lieu || inscription.lieu
                  })
              }"
              v-bind:espace="espace"
              v-bind:produit="true" />
          </template>
        </transition-group>
      </div>
    </template>
  </div>
</template>

<script>
  import inscriptionCard from '@/components/utils/inscription-card';

  export default {
    components: { inscriptionCard },
    props: {
      inscription: {
        type: Object,
        default: null
      },
      produits: {
        type: Array,
        default: () => []
      },
      collapse: {
        type: Boolean,
        default: false
      },
      espace: {
        type: String,
        default: 'participant'
      }
    },
    emits: ['open-positionnement'],
    data: () => ({ collapsed: false }),
    methods: {
      getTypeList(classe_virtuelle, type, ficheType) {
        const typeList = {
          id: type.id,
          label: ficheType ? ficheType.label : type.label
        };

        return classe_virtuelle
          ? [
              {
                label: 'Classe virtuelle',
                id: 5
              }
            ]
          : [typeList];
      },
      toggleCollapse() {
        this.collapsed = !this.collapsed;
      },
      openCollapse() {
        // method utilisée par un component parent
        this.collapsed = false;
      },
      openPositionnement(value) {
        this.$emit('open-positionnement', value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .collapsed {
    @apply border-transparent;
  }
</style>
