<template>
  <div
    class="refonte-side-menu-link"
    v-bind:class="props.active ? 'side-menu-link-active' : ''"
    v-on:click="props.url && !props.active && goTo(props.url, props.replace)"
    v-bind:title="props.label ?? undefined">
    <div class="side-menu-link-icon">
      <slot></slot>
    </div>
    <div class="side-menu-link-text">
      {{ props.label }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useRouter } from 'vue-router';

  interface Link {
    label: string;
    url?: string;
    replace?: boolean;
    active?: boolean;
  }

  const props = defineProps<Link>();

  const router = useRouter();

  function goTo(url: string, replace: boolean) {
    if (replace) {
      location.href = url;
    } else {
      router.push({ path: url });
    }
  }
</script>

<style lang="scss" scoped>
  .refonte-side-menu-link {
    @apply flex cursor-pointer items-center self-stretch hover:bg-refonte-white-10;
    gap: 12px;
    padding: 10px 12px;
    border-radius: 8px;

    .side-menu-link-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 20px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte-white;
        width: 20px;
        height: 20px;
      }
    }
    .side-menu-link-text {
      @apply flex-grow whitespace-nowrap;
    }

    &.side-menu-link-active {
      @apply bg-refonte-white-10;
    }
  }
  @media (min-width: 768px) {
    .side-menu--reduced {
      .refonte-side-menu-link {
        @apply justify-center;

        .side-menu-link-text {
          @apply hidden;
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .refonte-side-menu-link {
      padding: 8px 12px;
    }
  }
</style>
