const formatter = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR'
});

const formatterWithNoDigits = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'EUR'
});

export default {
    methods: {
        formatCurrency(value) {
            return formatter.format(value);
        },
        formatCurrencyNoDigit(value) {
            return formatterWithNoDigits.format(value);
        }
    }
};
