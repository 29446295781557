import { formationsData } from '@/stores/data/formations-data';
import {
    addSessionTypeLabel,
    addSessionTypeShortcut,
    buildDate,
    buildPifDocumentStatut,
    buildProduitTypeLabel,
    buildProduitTypeShortcut
} from '@/stores/utils';
import { Filters } from '@/types/participant/formations-filters-types';
import {
    Formation,
    FormationResponse
} from '@/types/participant/formations-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    action: undefined,
    typeIds: undefined
};

const filterInitialState: Filters = {
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    action: undefined,
    typeIds: undefined
};

export const FORMATIONS_FILTERS_CONFIGURATION = {
    formationId: {
        name: 'formationId',
        isArray: false
    },
    date: {
        name: 'date',
        isArray: false
    },
    datePeriodeId: {
        name: 'datePeriodeId',
        isArray: false
    },
    datePeriodeDebut: {
        name: 'datePeriodeDebut',
        isArray: false
    },
    datePeriodeFin: {
        name: 'datePeriodeFin',
        isArray: false
    },
    typeIds: {
        name: 'typeIds',
        isArray: true
    },
    action: {
        name: 'action',
        isArray: false
    }
};

export const useFormationFilterStore = defineStore('formations-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };

    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = FORMATIONS_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
            if (
                [
                    FORMATIONS_FILTERS_CONFIGURATION.date.name,
                    FORMATIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
                    FORMATIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
                ].includes(key)
            ) {
                newPayload[key] = new Date(newPayload[key]);
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useFormationStore = defineStore('formations', () => {
    const formations = ref<Formation[]>([]);

    const fetchFormations = async (query: Filters) => {
        /*const response = await API.get(`participant/v2/formations`, {
            params: query
        });
        if (response?.data) {
            formations.value = response?.data.map((i: InscriptionResponse) =>
                mapFormation(i)
            );
        }*/
        formations.value = formationsData.map((f) => mapFormation(f));
    };

    const mapFormation = (f: FormationResponse): Formation => ({
        ...f,
        typeShortcut: addSessionTypeShortcut(
            buildProduitTypeShortcut(f.type.id, f.lienElearning),
            f.sessionStatut?.id
        ),
        typeLabel: addSessionTypeLabel(
            buildProduitTypeLabel(f.type.label, f.lienElearning),
            f.sessionStatut?.id
        ),
        statutLabel:
            f?.statut?.id === -1 ? 'Partiellement confirmé' : f.statut.label,
        datesFormations: buildDate(f.dateDebut, f.dateFin),
        sessionStatutLabel: f.sessionStatut?.label,
        ...(f.pif?.id && {
            pifStatut: buildPifDocumentStatut(
                f.pif?.disponible,
                f.pif?.signee,
                f.pif?.validee
            )
        }),
        disabled: f.sessionStatut?.id === 5,
        ...(f.testPositionnement?.disponible &&
            !f.testPositionnement?.realise && {
                testPositionnementLabel: 'Test de positionnement'
            }),
        ...(f.testValidationAcquis?.disponible &&
            !f.testValidationAcquis?.realise && {
                testValidationsAcquiLabel: 'Test de validation des acquis'
            }),
        ...(f.satisfaction?.disponible &&
            !f.satisfaction?.realise && {
                satisfactionLabel: 'Quest. de satisfaction'
            }),
        ...(f.moduleElearning?.disponible &&
            !f.moduleElearning?.realise && {
                moduleElearningLabel: 'Module e-learning'
            })
    });
    return {
        formations,
        fetchFormations
    };
});
