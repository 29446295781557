<template>
  <div class="p-[8px]">
    <template v-if="formations.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="formations-1"
        table_index="formations-1"
        v-bind:columns="columns"
        v-bind:data="formations">
        <template v-slot:itemTags="{ rowIndex }">
          <refonte-tag v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{ rowIndex }}</refonte-number>
          </refonte-tag>
        </template>
      </refonte-table>
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { h } from 'vue';
  import { useFormationStore } from '@/stores/participant/formations';
  import { Formation } from '@/types/participant/formations-types';
  import FormationsTableItemDocuments from '@/views/_espace/_refonte/participant/_formations/formations-table-item-documents.vue';
  import FormationsTableItemActions from '@/views/_espace/_refonte/participant/_formations/formations-table-item-actions.vue';
  import { useRouter } from 'vue-router';
  import { FormationsDetail } from '@/types/participant/formations-details-types';

  const props = withDefaults(
    defineProps<{
      fetch?: Function;
    }>(),
    {}
  );

  const columns = [
    {
      content_index: 'reference',
      label: 'Référence',
      min_width: 116,
      width: 120
    },
    {
      content_index: 'titre',
      tags_type: 'typeShortcut',
      tags_type_label: 'typeLabel',
      content_sub_text: (row: Formation) => `${row.lieu ?? ''}`,
      tag_text: (row: Formation) => row.produit?.titre,
      tag_type_label: (row: Formation) => row.produit?.type.label,
      tag_type_id: (row: Formation) => row.produit?.type.id,
      min_width: 240,
      width: 360
    },
    {
      content_index: 'datesFormations',
      label: 'Dates de formation',
      content_sub_text: (row: Formation) =>
        row.inProgress ? 'En cours' : undefined,
      content_sub_text_class: (row: Formation) =>
        row.inProgress ? '!text-danger-500' : undefined,
      min_width: 110,
      width: 190
    },

    {
      content_index: 'statutLabel',
      label: 'Statut',
      width: 85,
      content_custom: (row: Formation) =>
        h('span', {
          innerHTML: row.statutLabel,
          class:
            row.statutLabel === 'Annulé' || row.statutLabel === 'Transféré'
              ? 'text-jinius-red'
              : ''
        })
    },
    {
      content_index: 'organisateur',
      label: 'Organisateur',
      min_width: 100,
      width: 100
    },
    {
      content_index: 'action',
      label: 'Actions à réaliser',
      min_width: 200,
      width: 250,
      tooltip: ' ',
      content_custom: (row: Formation) => {
        if (row.disabled) {
          return h('span', '-');
        }
        return h(FormationsTableItemActions, {
          row,
          refresh_rows: props.fetch ? props.fetch : () => {}
        });
      },
      class: (row: Formation) => {
        return '!flex-grow-0';
      }
    },
    {
      content_index: 'id',
      label: 'Documents',
      min_width: 150,
      width: 200,
      tooltip: ' ',
      content_custom: (row: Formation) => {
        if (row.disabled) {
          return h('span', '-');
        }
        return h(FormationsTableItemDocuments, {
          row,
          refresh_rows: props.fetch ? props.fetch : () => {}
        });
      },
      class: (row: Formation) => {
        if (row.attestationId || row.pifStatut) {
          return '!bg-refonte-pale-violet-2 !flex-grow-0 ';
        }
        return '!flex-grow-0';
      }
    }
  ];

  const formationStore = useFormationStore();

  const { formations } = storeToRefs(formationStore);
  const router = useRouter();
  const goToDetails = (row: FormationsDetail) => {
    if (row?.type.id == 2) {
      router.push(`/inscription/formations-details-elearning?id=${row.id}`);
    } else {
      router.push(`/inscription/formations-details?id=${row.id}`);
    }
  };
</script>

<style lang="scss" scoped></style>
