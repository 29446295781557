<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close">
    <template v-slot:modalBody>
      Si vous fermez maintenant, vos réponses ne seront pas enregistrées.
      Êtes-vous sûr de vouloir quitter sans enregistrer ?
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="close">
        Annuler et continuer
      </refonte-button>
      <refonte-button v-bind:on_click="submit"> Fermer </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  function close() {
    props.on_close();
  }
  function submit(e: Event) {
    e.preventDefault();
    props.on_submit && props.on_submit();
    close();
  }
</script>

<style lang="scss" scoped></style>
